export async function request<T = any>(...args: Parameters<typeof fetch>): Promise<T> {
  const [path] = args;
  const response = await fetch(...args)

  if (!response.ok) {
    throw Object.assign(new Error(`Request ${response.status} error from ${path}`), { response });
  }

  return response.json();
}
