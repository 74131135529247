import { Helmet } from 'react-helmet';
import { useTranslation } from '../../utils/intl';
import { Responsive } from '../../components/Responsive';
import { DataProtectionContent } from '../../components/DataProtectionContent';

export const DataProtection = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('dataProtection.title')}</title>
        <meta name="description" content={t('dataProtection.description')} />
      </Helmet>
      
      <Responsive size="lg">
        <h1 className="page-title">
          {t('dataProtection.header')}
        </h1>

        <DataProtectionContent />
      </Responsive>
    </>
  );
};
