import { useMemo, useCallback } from 'react';
import { FormikHelpers } from 'formik';
import { useTranslation } from '../../utils/intl';
import { RegLocatorNumberSymbols, RegLocatorNumber } from '../../utils/constants';
import { Form, FormStatus } from '../Form';
import { FormFieldInput } from '../FormFieldInput';
import { FormFieldTags } from '../FormFieldTags';
import { FormFieldCheckbox } from '../FormFieldCheckbox';
import { DataProtectionPolicy } from './DataProtectionPolicy';
import { validationSchema, Values } from './validationSchema';

export interface RenewalFormProps {
  onSuccess: () => void;
  onError: () => void;
}

export function RenewalForm({ onSuccess, onError }: RenewalFormProps) {
  const { t, i18n } = useTranslation();

  const initialValues = useMemo(() => ({
    locatorNumbers: [],
    companyName: '',
    email: '',
    phone: '',
    consent: false,
    processing: false,
  }), []);

  const handleSubmit = useCallback(async (values: Values, { setErrors, setStatus }: FormikHelpers<Values>) => {
    setStatus();

    const postValues = {
      locatorNumbers: values.locatorNumbers.map((item) => item.content),
      companyName: values.companyName,
      email: values.email,
      phone: values.phone,
    }

    try {
      const response = await fetch('/v20220928/subscriptions/renewal', {
        method: 'POST',
        headers: {
          'Content-Language': i18n.language === 'pl' ? 'pl-PL' : 'en-US',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...postValues }),
      });

      if (response.ok) {
        onSuccess();
      } else {
        onError();
      }
    } catch (error) {
      console.error(error);
      return setStatus(FormStatus.UNEXPECTED);
    }
  }, [onSuccess, onError, i18n.language]);

  return (
    <Form<Values>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <FormFieldTags
        label={t('renewal.form.locatorNumbers')}
        name="locatorNumbers"
        placeholder="Z21-CN00SN-0, Z21-CN11SN-1, Z21-CN22SN-2"
        inputMatch={RegLocatorNumber}
        inputMatchError={t('renewal.form.locatorNumbersFormat')}
        inputSymbolMatch={RegLocatorNumberSymbols}
        inputSymbolMatchError={t('renewal.form.locatorNumbersInputSymbol')}
        inputMaxLen={12}
        inputMaxLenError={t('renewal.form.locatorNumbersInputMaxLen')}
      />
      <FormFieldInput label={t('renewal.form.companyName')} name="companyName" type="text" />
      <FormFieldInput label={t('renewal.form.email')} name="email" type="email" />
      <FormFieldInput label={t('renewal.form.phone')} name="phone" type="tel" />
      <FormFieldCheckbox label={t('renewal.form.processing')} name="processing" />
      <FormFieldCheckbox label={<DataProtectionPolicy />} name="consent" />
    </Form>
  );
}
