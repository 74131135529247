import { FC } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useTranslation } from '../../utils/intl';
import styles from './NavMenu.module.scss';

export const NavMenu: FC<unknown> = () => {
  const { t } = useTranslation();

  const itemClassName: NavLinkProps['className'] = ({ isActive }) =>
    isActive ? `${styles.item} ${styles.active}` : styles.item;

  const itemSecondaryClassName: NavLinkProps['className'] = ({ isActive }) =>
    isActive ? `${styles.item} ${styles.active}` : `${styles.item} ${styles.secondary}`;

  return (
    <div className={styles.root}>
      <nav className={styles.menu}>
        <NavLink to="/polish-etoll-renew-online" className={itemClassName}>
          {t('onlineRenewal.navLink')}
        </NavLink>
        <NavLink
          to="/request-for-polish-etoll-renewal"
          className={itemClassName}
        >
          {t('renewal.navLink')}
        </NavLink>
        <NavLink
          to="/check-polish-etoll-service"
          className={itemClassName}
        >
          {t('check.navLink')}
        </NavLink>
      </nav>
      <nav className={styles.menu}>
        <NavLink
          to="/get-support"
          className={itemSecondaryClassName}
        >
          {t('support.navLink')}
        </NavLink>
        <NavLink
          to="/data-protection"
          className={itemSecondaryClassName}
        >
          {t('dataProtection.navLink')}
        </NavLink>
        <NavLink
          to="/terms-and-conditions"
          className={itemSecondaryClassName}
        >
          {t('termsAndConditions.navLink')}
        </NavLink>
      </nav>
    </div>
  );
};
