import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../utils/intl';
import { Form } from '../Form';
import { FormFieldInput } from '../FormFieldInput';
import { validationSchema, Values } from './validationSchema';
import { FormikConfig } from 'formik/dist/types';

export function CheckServiceForm() {
  const { t } = useTranslation();
  const navigate = useNavigate()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback<FormikConfig<Values>['onSubmit']>(({ locatorNumber }) => navigate(`/check-polish-etoll-service/${locatorNumber}`), []);

  return (
    <Form<Values>
      initialValues={{ locatorNumber: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <FormFieldInput
        label={t('check.form.locatorNumber')}
        name="locatorNumber"
        type="text"
        placeholder="Z21-CN22SN-2"
      />
    </Form>
  );
}
