import { ErrorMessage, FormikValues } from 'formik';
import FormikCheckbox, { FormikCheckboxProps } from '@tt2/components/build/Atoms/FormikCheckbox';
import { FormFieldError } from '../FormFieldError';
import { FormField } from '../FormField';
import styles from './FormFieldCheckbox.module.scss';

export function FormFieldCheckbox<T extends FormikValues> ({
  name,
  label,
}: Pick<FormikCheckboxProps<T>, 'name' | 'label'>) {
  return (
    <FormField>
      <FormikCheckbox className={styles.input} name={name} isLabelLeft>
        {label}
      </FormikCheckbox>
      <ErrorMessage name={name} component={FormFieldError} />
    </FormField>
  );
}