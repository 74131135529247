import classNames from 'classnames';
import { Fragment, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { useTranslation } from '../../utils/intl';
import styles from './Breadcrumbs.module.scss';

interface BreadcrumbsProps {
  className?: string;
}

export function Breadcrumbs({ className }: BreadcrumbsProps) {
  const { t } = useTranslation();
  const routes = useMemo<BreadcrumbsRoute[]>(() => ([
    { path: '/request-for-polish-etoll-renewal', breadcrumb: t('renewal.breadcrumb') },
    { path: '/polish-etoll-renew-online', breadcrumb: t('onlineRenewal.breadcrumb') },
    { path: '/check-polish-etoll-service', breadcrumb: t('check.breadcrumb'), children:  [
        {
          path: ':locatorNumber',
          breadcrumb: ({ match }) => (
            <span>
              <span>&nbsp;/&nbsp;</span>
              <span>{match.params.locatorNumber}</span>
            </span>
          )
        }
      ]},
    { path: '/get-support', breadcrumb: t('support.breadcrumb') },
    { path: '/data-protection', breadcrumb: t('dataProtection.breadcrumb') },
    { path: '/terms-and-conditions', breadcrumb: t('termsAndConditions.breadcrumb') },
  ]), [t]);
  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true });

  return (
    <div className={classNames(styles.breadcrumbs, className)}>
      {breadcrumbs.map(({ match, breadcrumb }, index) => {
        if (index === breadcrumbs.length - 1) {
          return (
            <Fragment key={match.pathname}>
              {breadcrumb}
            </Fragment>
          );
        }

        return (
          <NavLink
            key={match.pathname}
            to={match.pathname}
          >
            {breadcrumb}
          </NavLink>
        );
      })}
    </div>
  )
}
