import { useCallback } from 'react';
import { ErrorMessage, FormikValues, useFormikContext } from 'formik';
import uniqueId from 'lodash/uniqueId';
import castArray from 'lodash/castArray';
import intersection from 'lodash/intersection';
import FormikInputTags from '@tt2/components/build/Molecules/FormikInputTags';
import type { FormikInputProps } from '@tt2/components/build/Atoms/FormikInput';
import { FormFieldError } from '../FormFieldError';
import { FormField } from '../FormField';
import styles from './FormFieldTags.module.scss';

const Separators = ['Enter', ','];

interface FormFieldTagsProps<T> extends Pick<FormikInputProps<T>, 'name' | 'label' | 'placeholder'> {
  inputMatch?: RegExp;
  inputMatchError?: string;
  inputMaxLen?: number;
  inputMaxLenError?: string;
  inputSymbolMatch?: RegExp;
  inputSymbolMatchError?: string;
}

export function FormFieldTags<T extends FormikValues> ({
  name,
  label,
  placeholder,
  inputMatch,
  inputMatchError,
  inputSymbolMatch,
  inputSymbolMatchError,
  inputMaxLen,
  inputMaxLenError,
}: FormFieldTagsProps<T>) {
  const { values, errors, setFieldValue, setFieldTouched, setFieldError } = useFormikContext<T>();
  const value = values[name];
  const error = errors[name] as string;

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const next = String(event.clipboardData.getData('Text') || '')
        .split(/\s*,\s*/)
        .filter(Boolean)
        .map((content) => ({
          content,
          eventKey: uniqueId('form-field-tags-'),
        }));

      if (next.length) {
        setFieldValue(name, [...value, ...next]);
        window.setTimeout(() => setFieldTouched(name, true, true), 0);
      }
    },
    [name, value, setFieldTouched, setFieldValue],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value.trim();

      if (
        event.key.length === 1
        && intersection(castArray(error), [inputSymbolMatchError, inputMaxLenError, inputMatchError]).length
      ) {
        setFieldError(name, undefined);
      }

      let hasMaxLenError = false;

      if (
        inputMaxLen
        && value.length >= inputMaxLen
        && !Separators.includes(event.key)
      ) {
        hasMaxLenError = true;
        if (event.key.length === 1) {
          event.preventDefault();
        }
        setFieldError(name, inputMaxLenError);
        window.setTimeout(() => setFieldTouched(name, true, false), 0);
      }

      if (
        inputMatch
        && inputMaxLen
        && event.key.length === 1
        && (value.length === inputMaxLen - 1 || (hasMaxLenError && value.length === inputMaxLen))
        && !Separators.includes(event.key)
        && !inputMatch.test(hasMaxLenError ? value : `${value}${event.key}`.trim())
      ) {
        setFieldError(name, inputMatchError);
        window.setTimeout(() => setFieldTouched(name, true, false), 0);
      }

      if (
        inputSymbolMatch
        && !hasMaxLenError
        && event.key.length === 1
        && !Separators.includes(event.key)
        && !inputSymbolMatch.test(event.key)
      ) {
        event.preventDefault();
        setFieldError(name, inputSymbolMatchError);
        window.setTimeout(() => setFieldTouched(name, true, false), 0);
      }
    },
    [
      name,
      error,
      inputMatch,
      inputMatchError,
      inputSymbolMatch,
      inputSymbolMatchError,
      inputMaxLen,
      inputMaxLenError,
      setFieldError,
      setFieldTouched,
    ],
  );

  return (
    <FormField>
      <FormikInputTags
        label={label}
        name={name}
        placeholder={placeholder}
        className={styles.input}
        // @ts-ignore
        onPaste={handlePaste}
        onKeyDown={handleKeyDown}
      />
      <ErrorMessage name={name} component={FormFieldError} />
    </FormField>
  );
}
