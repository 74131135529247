import React, { VFC } from 'react';
import Icon from '@tt2/components/build/Atoms/Icon';
import styles from './OnlineRenewal.module.scss';
import { Trans } from '../../utils/intl';

export const SuccessMessage: VFC = () => (
  <div className={styles.form}>
    <Icon className={styles.colorSuccess} isCentered name="complete" size={72} />
    <Trans parent="p">{'onlineRenewal.payment.success'}</Trans>
  </div>
)
