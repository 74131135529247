import { IState } from './types';
import { FC } from 'react';
import styles from './styles.module.scss';
import { Trans } from '../../utils/intl';
import { NavLink } from 'react-router-dom';

export interface SuccessScreenProps {
  locatorNumber: string;
  result: NonNullable<IState['result']>
}

export const SuccessScreen: FC<SuccessScreenProps> = ({ locatorNumber, result }) => {
  return (
    <>
      <h1 className="page-title">
        <Trans i18nKey="check.success.header" values={{ locatorNumber }} />
      </h1>
      <div>
        <h4 style={{ display: 'flex', gap: 5 }}>
          <Trans i18nKey="check.success.summary.heading" />: <div
          title={result.status.status}
          style={{
            width: 21,
            height: 21,
            backgroundColor: `rgb${result.status.rgb}`,
            borderRadius: '50%'
          }}
        ></div>
        </h4>
        <ul className={styles.list}>
          {result.status.messages.map((message) => (
            <Trans key={message} i18nKey={message} parent="li" />
          ))}
        </ul>
        <Trans i18nKey="check.success.additional.heading" parent="h4" />
        <ul className={styles.list}>
          {(['inPoland', 'onEtollPaidRoad', 'validCoordinateFrequency'] as const).map((prop) => (
            <li key={prop}>
              <Trans i18nKey={`check.success.additional.${prop}`} />: {result.status[prop] ?
              <Trans i18nKey="yes" parent="b" /> :
              <Trans i18nKey="no" parent="b" />}
            </li>
          ))}
          <li>
            <Trans i18nKey={`check.success.additional.billingProblemPending`} />: {!result.status.billingProblemPending ?
            <Trans i18nKey="yes" parent="b" /> :
            <Trans i18nKey="no" parent="b" />
          }
          </li>
        </ul>
        {result.expires && (
          <>
            <Trans i18nKey="check.success.subscription.heading" parent="h4" />
            <ul className={styles.list}>
              <Trans
                parent="li"
                i18nKey="check.success.subscription.expireAt"
                values={result.expires}
              />
              {result.expires.extendable && (
                <Trans
                  parent="li"
                  i18nKey="check.success.subscription.renew"
                  components={[<NavLink className={styles.link} to="/polish-etoll-renew-online" />]}
                />
              )}
            </ul>
          </>
        )}
      </div>
    </>
  );
};
