import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from '../../utils/intl';
import React, { FC, useCallback, useState } from 'react';
import styles from './OnlineRenewal.module.scss'
import { BusinessIdForm } from './BusinessIdForm';
import { UserDetailsForm } from './UserDetailsForm';
import { SuccessMessage } from './SuccessMessage';
import { ErrorMessage } from './ErrorMessage';
import { PaymentVerify } from './PaymentVerify';
import { Stepper } from './Stepper';

export interface OnlineRenewalProps {
  success?: boolean;
  step?: number;
}

export const OnlineRenewal: FC<OnlineRenewalProps> = ({ step, success }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(step ?? 0);
  const [locatorNumber, setLocatorNumber] = useState('');

  const onBusinessIdFormNext = useCallback((id: string) => {
    setLocatorNumber(id);
    setActiveStep(1);
  }, [])

  const onBack = useCallback(() => setActiveStep(activeStep - 1), [activeStep]);

  return (
    <>
      <Helmet>
        <title>{t('onlineRenewal.title')}</title>
        <meta name="description" content={t('onlineRenewal.description')} />
      </Helmet>
      <div className={styles.root}>
        <Trans parent="h1">{'onlineRenewal.header'}</Trans>
        <div className={styles.paper}>
          <div className={styles.stepper}>
            <Stepper
              steps={[
                t('onlineRenewal.businessId.label'),
                t('onlineRenewal.userDetails.label'),
                t('onlineRenewal.payment.label')
              ]}
              activeStep={activeStep}
            />
          </div>
          <div className={styles.body}>
            {activeStep === 0 && <BusinessIdForm onNext={onBusinessIdFormNext} />}
            {activeStep === 1 && <UserDetailsForm locatorNumber={locatorNumber} onBack={onBack} />}
            {activeStep === 2 && <PaymentVerify />}
            {activeStep === 3 && <>{success ? <SuccessMessage /> : <ErrorMessage />}</>}
          </div>
        </div>
        <div className={styles.explanation}>
          <span>
            <sup>*</sup> {t('onlineRenewal.explanation')}
          </span>
        </div>
      </div>
    </>
  )
}
