import { useTranslation } from '../../utils/intl';
import { Helmet } from 'react-helmet';
import { TemplateSwitcher } from './templates/TemplateSwitcher';

export const TermsAndConditions = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('termsAndConditions.title')}</title>
        <meta name="description" content={t('termsAndConditions.description')} />
      </Helmet>
      <TemplateSwitcher />
    </>
  );
};
