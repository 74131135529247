import classNames from 'classnames';
import { FC } from 'react';
import styles from './Responsive.module.scss';

interface ResponsiveProps {
  size?: 'lg';
}

export const Responsive: FC<ResponsiveProps> = ({ size, children }) => (
  <div
    className={classNames({
      [styles.responsiveMd]: !size,
      [styles.responsiveLg]: size === 'lg',
    })}
  >
    {children}
  </div>
);