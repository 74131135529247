import { ErrorMessage, FormikValues } from 'formik';
import FormikInput, { FormikInputProps } from '@tt2/components/build/Atoms/FormikInput';
import { FormFieldError } from '../FormFieldError';
import { FormField } from '../FormField';
import styles from './FormFieldInput.module.scss';

export function FormFieldInput<T extends FormikValues> ({
  name,
  label,
  type,
  placeholder,
}: Pick<FormikInputProps<T>, 'name' | 'label' | 'type' | 'placeholder'>) {
  return (
    <FormField>
      <FormikInput label={label} name={name} type={type} placeholder={placeholder} className={styles.input} />
      <ErrorMessage name={name} component={FormFieldError} />
    </FormField>
  );
}