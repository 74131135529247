import { useParams } from 'react-router-dom';
import React, { useEffect, useState, VFC } from 'react';
import { request } from '../../utils/request';
import Spinner from '@tt2/components/build/Atoms/Spinner';
import { ExpiresResponse, IState, StatusResponse } from './types';
import { Helmet } from 'react-helmet';
import { useTranslation } from '../../utils/intl';
import { ErrorScreen } from './ErrorScreen';
import { SuccessScreen } from './SuccessScreen';

export const Status: VFC = () => {
  const { t } = useTranslation();
  const { locatorNumber } = useParams<{ locatorNumber: string }>();
  const [{ result, running, error }, setState] = useState<IState>({ result: null, error: null, running: true })
  useEffect(() => {
    (async function getter() {
      try {
        const status = await request<StatusResponse>(`/v20230504/object/${locatorNumber}/status`, {
          method: 'GET',
        });

        const expires = await request<ExpiresResponse>(`/v20220928/subscriptions/${locatorNumber}`, {
          method: 'GET',
        }).catch(() => null);

        setState({ running: false, error: null, result: { status, expires } });
      } catch (err: any) {
        setState({ running: false, error: err, result: null });
      }
    })();
  }, [locatorNumber]);

  return (
    <>
      <Helmet>
        <title>{t('check.title')}</title>
        <meta name="description" content={t('check.description')} />
      </Helmet>
      {running && <Spinner isCentered size={72} />}
      {error && <ErrorScreen locatorNumber={locatorNumber!} />}
      {result && <SuccessScreen locatorNumber={locatorNumber!} result={result} />}
    </>
  );
};
