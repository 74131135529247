import { useLayoutEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

interface SwitchLangProps {
  lang: string,
  setBasename: (lang: string) => void,
}

function SwitchLang ({ lang, setBasename }: SwitchLangProps) {
  useLayoutEffect(() => {
    setBasename(lang)
  }, [ lang, setBasename ])

  return null
}

interface LangRoutesProps {
  setBasename: (lang: string) => void
}

const Langs = ['en', 'pl', 'ru'];

export function LangRoutes ({ setBasename }: LangRoutesProps) {
  return (
    <BrowserRouter>
      <Routes>
        {Langs.map((lang) => (
          <Route
            key={lang}
            element={(
              <SwitchLang
                lang={lang}
                setBasename={setBasename}
              />
            )}
            path={`${lang}/*`}
          />
        ))}
      </Routes>
    </BrowserRouter>
  )
}
