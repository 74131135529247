import { Form, Formik, FormikConfig } from 'formik';
import styles from './OnlineRenewal.module.scss';
import { Trans, useTranslation } from '../../utils/intl';
import FormikInput from '@tt2/components/build/Atoms/FormikInput';
import FormikCheckbox from '@tt2/components/build/Atoms/FormikCheckbox';
import { TermsAndConditions } from './TermsAndConditions';
import Button from '@tt2/components/build/Atoms/Button';
import React, { useCallback, VFC } from 'react';
import * as Yup from 'yup';
import i18n from 'i18next';

const schema = Yup.object({
  email: Yup.string().email(() => i18n.t('onlineRenewal.userDetails.emailError')).required(() => i18n.t('onlineRenewal.userDetails.emailRequired')),
  termsAndConditionsConsent: Yup.boolean().required().oneOf([true]),
  gdprConsent: Yup.boolean().required().oneOf([true]),
  serviceConsent: Yup.boolean().required().oneOf([true]),
})

export type Values = Yup.InferType<typeof schema>;

export interface UserDetailsFormProps {
  onBack: () => void;
  locatorNumber: string;
}

export const UserDetailsForm: VFC<UserDetailsFormProps> = ({ onBack, locatorNumber }) => {
  const { t } = useTranslation();

  const onSubmit = useCallback<FormikConfig<Values>['onSubmit']>(async ({ email }, { setStatus }) => {
    setStatus();

    try {
      const response = await fetch('/v20221206/payments', {
        method: 'POST',
        headers: {
          'Content-Language': i18n.language === 'pl' ? 'pl-PL' : 'en-US',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, locatorNumber }),
      });

      if (response.ok) {
        window.location = (await response.json()).paymentLink;
      } else {
        setStatus(Error('UNEXPECTED_ERROR'))
      }
    } catch (error) {
      setStatus(Error('UNEXPECTED_ERROR'));
    }
  }, [locatorNumber]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ gdprConsent: false, termsAndConditionsConsent: false, serviceConsent: false, email: '' }}
      validationSchema={schema}
    >
      {({ isValid, isSubmitting, status, resetForm }) => {
        const error = status instanceof Error;

        return (
          <Form className={styles.form}>
            <h2>{locatorNumber}</h2>
            {error && (
              <>
                <Trans parent="p">{'general.unexpectedError'}</Trans>
                <Button onClick={() => resetForm()}>{t('general.tryAgain')}</Button>
              </>
            )}
            {!error && (
              <>
                <Trans parent="p">{'onlineRenewal.userDetails.description'}</Trans>
                <FormikInput name="email" placeholder="emailadress@email.com" showErrorMessages />
                <FormikCheckbox name="termsAndConditionsConsent">
                  <TermsAndConditions />
                </FormikCheckbox>
                <FormikCheckbox name="gdprConsent">
                  <span style={{ whiteSpace: 'break-spaces' }}>
                    {t('onlineRenewal.userDetails.gdprConsent')}
                  </span>
                </FormikCheckbox>
                <FormikCheckbox name="serviceConsent">
                  <span style={{ whiteSpace: 'break-spaces' }}>
                    {t('onlineRenewal.userDetails.serviceConsent')}
                  </span>
                </FormikCheckbox>
                <div className={styles.actions}>
                  <Button inverted onClick={onBack}>{t('general.back')}</Button>
                  <Button
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >{t('onlineRenewal.userDetails.pay')}</Button>
                </div>
              </>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

