import { ReactNode } from 'react';
import { Form as FormikForm, Formik, FormikValues, FormikConfig } from 'formik';
import Button from '@tt2/components/build/Atoms/Button';
import { useTranslation } from '../../utils/intl';
import styles from './Form.module.scss';

interface FormProps {
  children: ReactNode;
}

export enum FormStatus {
  UNEXPECTED = 'UNEXPECTED',
}

export function Form <T extends FormikValues>({
  children,
  validationSchema,
  initialValues,
  onSubmit,
}: FormProps & Pick<FormikConfig<T>, 'onSubmit' | 'validationSchema' | 'initialValues'>) {
  const { t } = useTranslation();
  return (
    <Formik<T>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting }) => (
        <FormikForm>
          {children}
          <div className={styles.actions}>
            <div className={styles.error}>
              {status === FormStatus.UNEXPECTED
                ? t('general.unexpectedError')
                : null}
            </div>
            <Button type="submit" isLoading={isSubmitting} disabled={isSubmitting}>
              {t('general.submit')}
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
}
