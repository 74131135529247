import { FC } from 'react';
import styles from './FormFieldError.module.scss';

export const FormFieldError: FC<unknown> = ({ children }) => {
  const message = Array.isArray(children)
    ? Array.from(new Set(children.filter((item) => !!item.content).map((item) => item.content))).join(', ')
    : String(children);

  return (
    <small className={styles.error} dangerouslySetInnerHTML={{ __html: message }} />
  );
}
