import { ReactText, VFC } from 'react';
import styles from './Steppper.module.scss';
import cx from 'classnames';

export interface StepperProps {
  steps: ReactText[];
  activeStep: number;
}

export const Stepper: VFC<StepperProps> = ({ activeStep, steps }) => {
  return (
    <div className={styles.root}>
      {steps.map((label, idx) => (
        <div
          key={label}
          className={cx(styles.step, {
            [styles.active]: activeStep === idx,
            [styles.completed]: activeStep > idx
          })}
        >
          {idx !== 0 && <div className={styles.connector}><span></span></div> }
          <span className={styles.circle}>{idx + 1}</span>
          <span className={styles.label}>{label}</span>
        </div>
      ))}
    </div>
  )
}
