import { Trans, useTranslation } from '../../utils/intl';
import React, { SyntheticEvent, useState } from 'react';
import TextLink from '@tt2/components/build/Atoms/TextLink';
import Dialog from '@tt2/components/build/Atoms/Dialog';
import DialogContent from '@tt2/components/build/Atoms/DialogContent';
import { useSearchParams } from 'react-router-dom';
import { TemplateSwitcher } from '../TermsAndConditions/templates/TemplateSwitcher';

export const TermsAndConditions = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [, setSearchParams] = useSearchParams();

  return (
    <>
      <span style={{ whiteSpace: 'break-spaces' }}>
        <Trans
          components={[
            <TextLink
              style={{ marginLeft: 3 }} onClick={(e: SyntheticEvent) => {
              e.preventDefault();
              setOpen(true);
            }}
            />
          ]}
        >
          {'onlineRenewal.userDetails.termsAndConditionsConsent'}
        </Trans>
      </span>
      <Dialog isOpen={open} onClose={() => {
        setSearchParams();
        setOpen(false)
      }}>
        <DialogContent
          header={t('termsAndConditions.description')}
          onClose={() => {
            setOpen(false);
            setSearchParams();
          }}
          width={70}
          heightByContent
          widthByViewport
        >
          <TemplateSwitcher />
        </DialogContent>
      </Dialog>
    </>
  )
}
