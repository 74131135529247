import * as Yup from 'yup';
import type { Message } from 'yup/es/types';
import i18n from 'i18next';
import { RegLocatorNumber, RegPhoneNumber } from '../../utils/constants';

declare module "yup" {
  interface ArraySchema<T> {
    unique(
      message: Message,
      mapper?: (value: T, index?: number, list?: T[]) => T[]
    ): ArraySchema<T>;
  }
}

Yup.addMethod(Yup.array, 'unique', function(message, mapper = (a: unknown) => a) {
  return this.test('unique', message, (list: unknown[] = []) => list.length === new Set(list.map(mapper)).size);
});

export const validationSchema = Yup.object({
  locatorNumbers: Yup.array()
    .of(Yup.object({
      content: Yup.string().required().matches(
        RegLocatorNumber,
        String(i18n.t('renewal.form.locatorNumbersFormat')),
      ),
      eventKey: Yup.string().required(),
    }))
    .ensure()
    .compact()
    .unique(() => i18n.t('renewal.form.locatorNumbersUnique'), (value: any) => value.content)
    .min(1, () => i18n.t('renewal.form.locatorNumbersMin'))
    .max(500, () => i18n.t('renewal.form.locatorNumbersMax'))
    .required(() => i18n.t('renewal.form.locatorNumbersRequired')),
  companyName: Yup.string()
    .max(500, () => i18n.t('renewal.form.companyNameMax')),
  email: Yup.string()
    .min(3, () => i18n.t('renewal.form.emailMin'))
    .max(254, () => i18n.t('renewal.form.emailMax'))
    .required(() => i18n.t('renewal.form.emailRequired'))
    .email(() => i18n.t('renewal.form.emailFormat')),
  phone: Yup.string()
    .required(() => i18n.t('renewal.form.phoneRequired'))
    .matches(RegPhoneNumber, () => i18n.t('renewal.form.phoneFormat')),
  consent: Yup.bool()
    .oneOf([true], () => i18n.t('renewal.form.consentRequired')),
  processing: Yup.bool()
    .oneOf([true], () => i18n.t('renewal.form.processingRequired')),
});

export type Values = Yup.InferType<typeof validationSchema>;
