import { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { IntlProvider } from './utils/intl';
import { Main as Layout } from './layouts/Main';
import { LangRoutes } from './components/LangRoutes';
import { Renewal } from './pages/Renewal';
import { CheckService } from './pages/CheckService';
import { GetSupport } from './pages/GetSupport';
import { DataProtection } from './pages/DataProtection';
import { NotFound } from './pages/NotFound';
import { TermsAndConditions } from 'pages/TermsAndConditions';
import { OnlineRenewal } from './pages/OnlineRenewal';
import { Status } from 'pages/Status';

export const App = () => {
  const [basename, setBasename] = useState<string>()
  return (
    <IntlProvider lang={basename}>
      <LangRoutes setBasename={setBasename} />
      <BrowserRouter basename={basename}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Navigate to="polish-etoll-renew-online" replace />} />
            <Route path="request-for-polish-etoll-renewal" element={<Renewal />} />
            <Route path="polish-etoll-renew-online">
              <Route path="success" element={<OnlineRenewal key="success" step={3} success />} />
              <Route path="error" element={<OnlineRenewal key="error" step={3} />} />
              <Route path="payment/:sessionId" element={<OnlineRenewal key="payment" step={2} />} />
              <Route index element={<OnlineRenewal key="main" />} />
            </Route>
            <Route path="check-polish-etoll-service" element={<CheckService />} />
            <Route path="check-polish-etoll-service/:locatorNumber" element={<Status />} />
            <Route path="get-support" element={<GetSupport />} />
            <Route path="data-protection" element={<DataProtection />} />
            <Route path="terms-and-conditions" element={<TermsAndConditions />}></Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </IntlProvider>
  );
}
