import Icon from '@tt2/components/build/Atoms/Icon';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavMenu } from '../NavMenu';
import styles from './MobileNavMenu.module.scss';

export function MobileNavMenu() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false)
  }, [location.key]);

  return (
    <>
      <button
        className={styles.btn}
        type="button"
        onClick={() => setIsOpen(true)}
      >
        <Icon className={styles.icon} name="menu" size={28} />
      </button>

      <aside className={classNames(styles.aside, isOpen && styles.open)}>
        <div className={styles.header}>
          <button
            className={styles.btn}
            type="button"
            onClick={() => setIsOpen(false)}
          >
            <Icon className={styles.icon} name="close" size={28} />
          </button>
        </div>
        <NavMenu />
      </aside>
    </>
  );
}
