import { Form, Formik, FormikConfig } from 'formik';
import styles from './OnlineRenewal.module.scss';
import FormikInput from '@tt2/components/build/Atoms/FormikInput';
import Button from '@tt2/components/build/Atoms/Button';
import { Trans, useTranslation } from '../../utils/intl';
import React, { FC, useCallback, VFC } from 'react';
import * as Yup from 'yup';
import { RegLocatorNumber } from '../../utils/constants';
import i18n from 'i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import Icon from '@tt2/components/build/Atoms/Icon';
import dayjs from 'dayjs';

export const schema = Yup.object({
  locatorNumber: Yup.string()
    .matches(
      RegLocatorNumber,
      () => i18n.t('check.form.locatorNumberFormat'),
    )
    .required(() => i18n.t('check.form.locatorNumberRequired')),
});

export type Values = Yup.InferType<typeof schema>;

export interface BusinessIdFormProps {
  onNext: (locatorNumber: string) => void;
}

export const BusinessIdForm: FC<BusinessIdFormProps> = ({ onNext }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = useCallback<FormikConfig<Values>['onSubmit']>(async ({ locatorNumber }, { setStatus }) => {
    try {
      const response = await fetch(`/v20220928/subscriptions/${locatorNumber}`, { method: 'GET' });

      if (response.status !== 200 && response.status !== 404) setStatus(Error('UNEXPECTED_ERROR'));
      if (response.status === 200) setStatus(await response.json());
      if (response.status === 404) setStatus(Error('NOT_FOUND'));
    } catch (error) {
      console.error(error);
      setStatus(Error('UNEXPECTED_ERROR'));
    }
  }, []);

  return (
    <Formik<Values> onSubmit={onSubmit} initialValues={{ locatorNumber: '' }} validationSchema={schema}>
      {({ status, isValid, isSubmitting, resetForm, values: { locatorNumber } }) => (
        <>
          <Form className={styles.form}>
            {!status && (
              <>
                <h2>{t('onlineRenewal.businessId.header')}</h2>
                <p>{t('onlineRenewal.businessId.description')}</p>
                <FormikInput name="locatorNumber" placeholder="Z21-CN00CN-0" showErrorMessages />
                <Button type="submit" disabled={!isValid || isSubmitting}>{t('general.submit')}</Button>
              </>
            )}
            {status && <h2>{locatorNumber}</h2>}
            {status instanceof Error && (
              <>
                {status.message === 'NOT_FOUND' && (
                  <>
                    <Trans style={{ marginBottom: 20 }} parent="p">{'onlineRenewal.businessId.notFound'}</Trans>
                    <div className={styles.actions}>
                      <Button inverted onClick={() => resetForm()}>{t('general.back')}</Button>
                      <Button onClick={() => navigate('/request-for-polish-etoll-renewal')}>{t('general.next')}</Button>
                    </div>
                  </>
                )}
                {status.message === 'UNEXPECTED_ERROR' && (
                  <>
                    <Trans parent="p">{'general.unexpectedError'}</Trans>
                    <Button onClick={() => resetForm()}>{t('general.tryAgain')}</Button>
                  </>
                )}
              </>
            )}
            {status?.expireAt && (
              <>
                {!status.extendable && (
                  <>
                    <NotExtendableMessage expireAt={status.expireAt} />
                    <Button onClick={() => resetForm()}>{t('general.back')}</Button>
                  </>
                )}
                {status.extendable && (
                  <>
                    <Trans parent="p" values={status} style={{ marginBottom: 20 }}>
                      {'onlineRenewal.businessId.eligible'}
                    </Trans>
                    <div className={styles.actions}>
                      <Button inverted onClick={() => resetForm()}>{t('general.back')}</Button>
                      <Button onClick={() => onNext(locatorNumber)}>{t('general.next')}</Button>
                    </div>
                  </>
                )}
              </>
            )}
          </Form>
        </>
      )}
    </Formik>
  )
};

const NotExtendableMessage: VFC<{ expireAt: string }> = ({ expireAt }) => {
  const expired = dayjs().diff(expireAt, 'd') > 0;

  return (
    <>
      <div>
        {expired && <Icon className={styles.colorWarning} isCentered name="error_large" size={72} />}
        {!expired && <Icon className={styles.colorSuccess} isCentered name="complete" size={72} />}
      </div>
      {expired && <Trans parent="p" components={[<NavLink to="/get-support" />]}>{'onlineRenewal.businessId.expired'}</Trans>}
      {!expired && <Trans parent="p">{'onlineRenewal.businessId.valid'}</Trans>}
    </>
  );
};
