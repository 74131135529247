import { NavLink } from 'react-router-dom';
import logo from '../../assets/mobile-logo.svg';
import styles from './MobileLogo.module.scss';

export const MobileLogo = () => (
  <div className={styles.logo}>
    <NavLink to="/" className={styles.logoLink}>
      <img src={logo} alt="Ruptela" height={26} width={26} />
    </NavLink>
  </div>
);