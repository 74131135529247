import { SyntheticEvent, useState } from 'react';
import Dialog from '@tt2/components/build/Atoms/Dialog';
import DialogContent from '@tt2/components/build/Atoms/DialogContent';
import TextLink from '@tt2/components/build/Atoms/TextLink';
import { Trans, useTranslation } from '../../utils/intl';
import { DataProtectionContent } from '../DataProtectionContent';

export function DataProtectionPolicy() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Trans
        i18nKey="renewal.form.consent"
        components={[
          <TextLink
            onClick={(event: SyntheticEvent) => {
              event.preventDefault();
              setIsOpen(true);
            }}
          />
        ]}
      />

      <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <DialogContent
          header={t('dataProtection.header')}
          onClose={() => setIsOpen(false)}
          width={70}
          heightByContent
          widthByViewport
        >
          <DataProtectionContent />
        </DialogContent>
      </Dialog>
    </>
  );
}