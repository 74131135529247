import React, { FC } from 'react';
import { Trans, useTranslation } from '../../utils/intl';
import { NavLink } from 'react-router-dom';

export interface ErrorScreenProps {
  locatorNumber: string
}

export const ErrorScreen: FC<ErrorScreenProps> = ({ locatorNumber }) => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="page-title">
        {t('check.error.header')}
      </h1>
      <div>
        <Trans
          i18nKey="check.error.content"
          values={{ locatorNumber }}
          components={[<NavLink to="/get-support" />]}
        />
      </div>
    </>
  );
};
