import { Trans } from '../../utils/intl';
import styles from './DataProtectionContent.module.scss';

export const DataProtectionContent = () => (
  <div className={styles.content}>
    <Trans
      i18nKey="dataProtection.content"
      components={[
        <div className={styles.listItem} />,
        <div className={styles.para} />,
      ]}
    />
  </div>
);
