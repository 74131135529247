import { useTranslation } from '../../utils/intl';

export const NotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      {t('notfound.header')}
    </>
  );
};
