import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from '../../utils/intl';
import { RenewalForm, RenewalFormProps } from '../../components/RenewalForm';
import { Notes } from '../../components/Notes';
import { Responsive } from '../../components/Responsive';

enum ResultStatus {
  Error = 'error',
  Success = 'success'
}

const ScreenForm = ({ onError, onSuccess }: Pick<RenewalFormProps, 'onSuccess' | 'onError'>) => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="page-title">
        {t('renewal.form.header')}
      </h1>
  
      <Notes
        items={[
          t('renewal.form.note0'),
          t('renewal.form.note1'),
        ]}
      />
  
      <Responsive>
        <RenewalForm onError={onError} onSuccess={onSuccess} />
      </Responsive>
    </>
  );
};

const ScreenResultError = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="page-title">
        {t('renewal.error.header')}
      </h1>
      <div>
        <Trans i18nKey="renewal.error.content" components={[<NavLink to="/get-support" />]} />
      </div>
    </>
  );
};

const ScreenResultSuccess = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="page-title">
        {t('renewal.success.header')}
      </h1>
      <div>
        <Trans i18nKey="renewal.success.content" />
      </div>
    </>
  );
};

export const Renewal = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<ResultStatus | null>(null);
  const location = useLocation();

  useEffect(() => {
    setStatus(null)
  }, [location.key]);

  return (
    <>
      <Helmet>
        <title>{t('renewal.title')}</title>
        <meta name="description" content={t('renewal.description')} />
      </Helmet>

      {status === ResultStatus.Error ? (
        <ScreenResultError />
      ) : status === ResultStatus.Success ? (
        <ScreenResultSuccess />
      ) : (
        <ScreenForm
          onError={() => setStatus(ResultStatus.Error)}
          onSuccess={() => setStatus(ResultStatus.Success)}
        />
      )}
    </>
  );
};
