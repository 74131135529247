/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { useTranslation, Trans } from '../../utils/intl';
import { Responsive } from '../../components/Responsive';
import styles from './GetSupport.module.scss';

export const GetSupport = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('support.title')}</title>
        <meta name="description" content={t('support.description')} />
      </Helmet>

      <Responsive size="lg">
        <Trans i18nKey="support.contacts" components={[<a href="mailto:support@linqo.pl" />]} />
        <br /><br />
        
        <h1 className="page-title">
          {t('support.header')}
        </h1>

        <Trans
          i18nKey="support.content"
          components={[
            <h3 className={styles.sectionTitle} />,
            <a href="https://etoll.gov.pl/media/kfhjt51x/lista-operatorow-obu-zsl-dopuszczonych-do-e-toll-14112023.pdf" target="_blank" rel="noreferrer" />,
            <a href="https://etoll.gov.pl/en/registration-in-e-toll/those-concerned/" target="_blank" rel="noreferrer" />,
            <a href="https://etoll.gov.pl" target="_blank" rel="noreferrer" />,
            <NavLink to="/check-polish-etoll-service" />,
            <a href="https://etoll.gov.pl/rejestracja-w-e-toll/kogo-dotyczy/" target="_blank" rel="noreferrer" />,
          ]}
        />
      </Responsive>
    </>
  );
};
