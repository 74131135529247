import successIcon from '../assets/icon-success.svg';
import infoIcon from '../assets/icon-info.svg';
import clsx from 'clsx';

export function DownloadLink({ href, className, children, ...rest }: JSX.IntrinsicElements['a']) {
  const url = href?.startsWith('/') ? `${process.env.PUBLIC_URL}${href}` : href;

  return (
    <a
      className={clsx("download-link", className)}
      rel="noreferrer"
      target="_blank"
      href={url}
      {...rest}
    >{children}</a>
  );
}

export function HelpLinks({ variant }: { variant: 'register' | 'success' | 'partial' }) {
  const success = variant === 'success';

  return (
    <>
      {success && <p className="paragraph"><strong>Now you need to assign your OBU device to your vehicle.</strong></p>}
      <section className="help-links" style={{ marginTop: success ? undefined : '60px' }}>
        {!success && <p className="paragraph">Having trouble?</p>}
        {success && <p className="paragraph">Please follow instructions provided:</p>}
        <ul className="reference-list">
          <li><DownloadLink href="/files/Ruptela_OBU.pdf">OBU installation instructions</DownloadLink></li>
          <li><DownloadLink href="/files/eTOLLregistrationinstructions.pdf">OBU registration instructions</DownloadLink></li>
          <li><DownloadLink href="https://etoll.gov.pl/en/heavy-vehicles/registration-in-e-toll/how-to-register-for-e-toll/">Company registration instructions</DownloadLink></li>
        </ul>
      </section>
    </>
  )
}

export function Heading({ variant }: { variant: 'register' | 'success' | 'partial' }) {
  if (variant === 'success') {
    return (
      <>
        <img alt="" src={successIcon} className="response-icon" aria-hidden="true" />
        <h1 className="heading heading-small">
          Your OBU device registration is successful!
        </h1>
        <p className="paragraph">Below provided information is also sent to your email.</p>
      </>
    );
  }

  if (variant === 'partial') {
    return (
      <>
        <img alt="" src={infoIcon} className="response-icon" aria-hidden="true" />
        <h1 className="heading heading-small">
          This tracking device IMEI is already registered.
        </h1>
        <p className="paragraph">Below provided information is also sent to your email.</p>
      </>
    );
  }

  return (
    <>
      <h1 className="heading">
        <span>Thank You</span>
        <small className="small-print"> for purchasing Polish eTOLL tracking device from Ruptela.</small>
      </h1>
      <p className="paragraph">Please register your OBU device:</p>
    </>
  );
}
