import styles from './Template.module.scss';
import { DownloadLink } from '../../../components/views';

export const Pl = () => {
  return (
    <div className={styles.termsAndConditions}>
      <div>
        <span>SPIS TREŚCI:</span>
        <ul>
          <li><a href="#Postanowienia ogólne">Postanowienia ogólne</a></li>
          <li><a href="#Definicje">Definicje</a></li>
          <li><a href="#Ogólna charakterystyka sklepu internetowego">Ogólna charakterystyka sklepu internetowego</a></li>
          <li><a href="#Zamówienia i ich realizacja">Zamówienia i ich realizacja</a></li>
          <li><a href="#Rozwiązanie i wygaśniecie umowy o świadczenie usług">Rozwiązanie i wygaśniecie umowy o świadczenie usług</a></li>
          <li><a href="#Ochrona danych osobowych">Ochrona danych osobowych</a></li>
          <li><a href="#Odpowiedzialność">Odpowiedzialność</a></li>
          <li><a href="#Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń
          oraz zasady dostępu do tych procedur">Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń
          oraz zasady dostępu do tych procedur</a></li>
          <li><a href="#Odstąpienie od umowy sprzedaży">Odstąpienie od umowy sprzedaży</a></li>
          <li><a href="#Postanowienia dotyczące Klientów będących Przedsiębiorcami">Postanowienia dotyczące Klientów będących Przedsiębiorcami</a></li>
          <li><a href="#Wady usługi. Reklamacje.">Wady usługi. Reklamacje.</a></li>
          <li><a href="#Postanowienia końcowe">Postanowienia końcowe</a></li>
        </ul>
      </div>
      <ul>
        <div id="Postanowienia ogólne">
          <li>
            <a href="#Postanowienia ogólne">POSTANOWIENIA OGÓLNE</a>
          </li>
          <ul>
            <li>Niniejszy Regulamin Sklepu Internetowego (zwany
            dalej <strong>„Regulaminem”</strong>) określa zasady korzystania ze Sklepu
            Internetowego, w tym między innymi składania i realizacji Zamówień,
            tryb postępowania reklamacyjnego, uprawnienia związane z
            odstąpieniem od Umowy sprzedaży.</li>
            <li>Niniejszy Regulamin jest skierowany zarówno do Klientów będących
            Konsumentami, będących Przedsiębiorcami z uprawnieniami konsumentów,
            jak i do Przedsiębiorców korzystających ze Sklepu Internetowego.</li>
            <li>Regulamin jest udostępniany Klientowi pod adresem [Link do
            Regulaminu sklepu] nieodpłatnie, przed zawarciem Umowy
            sprzedaży, w sposób umożliwiający pozyskanie, odtworzenie i
            utrwalanie treści Regulaminu za pomocą Systemu Teleinformatycznego,
            którym posługuje się Klient.</li>
            <li>Klient zobowiązany jest do przestrzegania wszystkich postanowień
            Regulaminu. Sprzedaż odbywa się na podstawie wersji Regulaminu,
            która jest obowiązująca i zaakceptowana przez Klienta w momencie
            złożenia Zamówienia.</li>
            <li>Zawarcie umowy o świadczenie drogą elektroniczną usług wskazanych w
            niniejszym Regulaminie następuje pod warunkiem, zapoznania się i
            akceptacji treści niniejszego Regulaminu.</li>
          </ul>
        </div>
        <div id="Definicje">
          <li>
            <a href="#Definicje">DEFINICJE</a>
          </li>
          <ul>
            <li>
              Ilekroć w Regulaminie jest mowa o:
              <ul>
                <li>
                  <strong>„Dzień roboczy”</strong> – należy przez to rozumieć jeden dzień od poniedziałku do piątku z wyłączeniem dni ustawowo wolnych od pracy.
                </li>
                <li>
                  <strong>„Sprzedawcy/Usługodawcy”</strong> – należy przez to LINQO Sp. z o.o. siedzibą we Wrocławiu przy Podwale 1/30 50-043 Wrocław, wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Wrocławia-Fabrycznej we Wrocławiu VI Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000537198, posiadającą nr NIP 8971804573 i nr REGON 360468661, o kapitale zakładowym w wysokości 50.000,00 zł, e-mail: <a href="mailto:renewal@ruptela.com">renewal@ruptela.com</a>, numer telefonu <a href="tel:+48 71 7575444">+48 71 7575444</a> - który prowadzi Sklep Internetowy i za jego pośrednictwem dokonuje sprzedaży Usług.
                </li>
                <li>
                  <strong>„Kliencie”</strong> – osoba fizyczna, osoba prawna oraz jednostka organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną, nabywająca Usługi za pośrednictwem Sklepu internetowego.
                </li>
                <li>
                  <strong>„Konsumencie”</strong> – należy przez to rozumieć konsumenta w rozumieniu art. 22<sup>1</sup> Kodeksu cywilnego, tj. osobę fizyczną dokonującą z przedsiębiorcą czynności prawnej niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową.
                </li>
                <li>
                  <strong>„Przedsiębiorcy”</strong> – należy przez to rozumieć przedsiębiorcę w rozumieniu art. 43<sup>1</sup> Kodeksu cywilnego, tj. osoba fizyczna, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną, prowadząca we własnym imieniu działalność gospodarczą lub zawodową, zawierający ze Sprzedawcą Umowę Sprzedaży lub korzystający z usług świadczonych przez Sprzedawcę drogą elektroniczną.
                </li>
                <li>
                  <strong>„Przedsiębiorcy z uprawnieniami Konsumenta”</strong> – należy przez to rozumieć osobę fizyczną zawierającą Umowę sprzedaży bezpośrednio związaną z jej działalnością gospodarczą, gdy z treści Umowy sprzedaży wynika, że nie posiada ona dla tego Przedsiębiorcy zawodowego charakteru, wynikającego w szczególności z przedmiotu wykonywanej przez niego działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej, która zawarła lub zamierza zawrzeć Umowę Sprzedaży ze Sprzedawcą lub korzystający z usług świadczonych przez Sprzedawcę drogą elektroniczną.
                </li>
                <li>
                  <strong>„Stronach”</strong> – należy przez to rozumieć łącznie Sprzedawcę i Konsumenta lub Sprzedawcę i Przedsiębiorcę lub Sprzedawcę i Przedsiębiorcę z uprawnieniami konsumenta.
                </li>
                <li>
                  <strong>„Umowie sprzedaży”</strong> – należy przez to rozumieć umowę zawartą na odległość, której przedmiotem jest sprzedaż przez Sprzedawcę Usługi na rzecz Klienta zgodnie z zasadami określonymi w Regulaminie.
                </li>
                <li>
                  <strong>„Sklepie Internetowym”</strong> – należy przez to rozumieć platformę internetową <a target="_blank" rel="noreferrer" href="https://tolls.ruptela.com">https://tolls.ruptela.com</a> administrowaną przez Sprzedawcę, dostępną on-line, za pośrednictwem której Klient, może dokonać zakupu Usług.
                </li>
                <li>
                  <strong>„Systemie Teleinformatycznym”</strong> – należy przez to rozumieć zespół współpracujących ze sobą urządzeń informatycznych i oprogramowania, zapewniający przetwarzanie i przechowywanie, a także wysyłanie i odbieranie danych poprzez sieci telekomunikacyjne za pomocą właściwego dla danego rodzaju sieci telekomunikacyjnego urządzenia końcowego w rozumieniu ustawy z dnia 16 lipca 2004 r. – Prawo telekomunikacyjne (tj.. Dz.U. z 2017 r. poz. 1907 ze zm.).
                </li>
                <li>
                  <strong>„Trwały nośnik”</strong> – należy przez to rozumieć materiał lub narzędzie umożliwiające Klientowi lub Sprzedawcy przechowywanie informacji kierowanych osobiście do niego, w sposób umożliwiający dostęp do informacji w przyszłości przez czas odpowiedni do celów, jakim te informacje służą, i które pozwalają na odtworzenie przechowywanych informacji w niezmienionej postaci.
                </li>
                <li>
                  <strong>„Usługa”</strong> lub <strong>”Abonament e-TOLL”</strong> – należy przez to rozumieć usługę polegającą na wysyłaniu, odbieraniu, przechowywaniu, przetwarzaniu oraz udostępnianiu organom Krajowej Administracji Skarbowej (dalej: KAS) danych lokalizacyjnych umożlwiających prawidłowe naliczenie opłat drogowych w  systemie e-TOLL.
                </li>
                <li>
                  <strong>„Adresie Elektronicznym”</strong> – należy przez to rozumieć oznaczenie Systemu Teleinformatycznego umożliwiające porozumiewanie się za pomocą środków komunikacji elektronicznej, w szczególności poczty elektronicznej;
                </li>
                <li>
                  <strong>„Usługobiorca”</strong> – należy przez to rozumieć osobę fizyczną, osobę prawną albo jednostkę organizacyjną nieposiadającą osobowości prawnej, której ustawa przyznaje zdolność prawną korzystająca z Usługi Elektronicznej.
                </li>
                <li>
                  <strong>„Usługa elektroniczna”</strong> – należy przez to rozumieć usługę świadczoną drogą elektroniczną przez Usługodawcę na rzecz Usługobiorcy za pośrednictwem Strony.
                </li>
                <li>
                  <strong>„Cenie”</strong> – należy przez to rozumieć wartość brutto Usługi wyrażoną w walucie PLN, uwzględniającą podatek od dóbr i usług. Całkowity koszt Zamówienia (tj. cena Usługi wraz z innymi kosztami) wskazany jest przed złożeniem Zamówienia przez Klienta.
                </li>
                <li>
                  <strong>„Danych Osobowych”</strong> – należy przez to rozumieć informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej.
                </li>
                <li>
                  <strong>„Administratorze Danych Osobowych”</strong> – należy przez to rozumieć Sprzedawcę, który występując w tej roli samodzielnie lub wspólnie z innymi ustala cele i sposoby przetwarzania Danych Osobowych.
                </li>
                <li>
                  <strong>„Regulaminie”</strong> – należy przez to rozumieć niniejszy dokument.
                </li>
                <li>
                  <strong>„RODO”</strong> – należy przez to rozumieć ROZPORZĄDZENIE PARLAMENTU EUROPEJSKIEGO I RADY (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz.U.UE.L.2016.119.1 z dnia 2016.05.04).
                </li>
                <li>
                  <strong>„Formularzu zamówienia”</strong> – należy przez to rozumieć Usługę Elektroniczną, interaktywny formularz dostępny w Sklepie Internetowym umożliwiający złożenie Zamówienia, poprzez dodanie numeru business ID Urządzenia OBU dla którego nabywana jest Usługa oraz określenie warunków Umowy Sprzedaży i płatności. Formularz zamówienia umożliwia także sprawdzenie okresu ważności Abonamentu e-TOLL dla posiadanego przez Klienta Urządzenia OBU oraz sprawdzenie zarejestrowania w bazie Usługodawcy posiadanego przez Klienta Urządzenia OBU.
                </li>
                <li>
                  <strong>„Zamówieniu”</strong> – należy przez to rozumieć oświadczenie woli Klienta, złożone za pośrednictwem Sklepu Internetowego określające: rodzaj i ilość zamawianych Usług; rodzaj dostawy; rodzaj i wysokość płatności; miejsce wydania rzeczy, dane Klienta, stanowiące ofertę zawarcia Umowy sprzedaży.
                </li>
                <li>
                  <strong>„Urządzenie OBU”</strong> (z ang. On Board Unit) to urządzenie pokładowe klasy GNSS, które przesyła dane geolokalizacyjne pojazdu do systemu e-TOLL, wykorzystując do tego transmisję danych. Dzięki OBU możliwe jest elektroniczne naliczanie opłat za przejazdy płatnymi drogami w kraju.
                </li>
                <li>
                  <strong>„Bussines ID”</strong> – unikalny numer przypisany do Urządzenia OBU.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div id="Ogólna charakterystyka sklepu internetowego">
          <li>
            <a href="#Ogólna charakterystyka sklepu internetowego">
              OGÓLNA CHARAKTERYSTYKA SKLEPU INTERNETOWEGO
            </a>
          </li>
          <ul>
            <li>Sklep Internetowy prowadzony jest przez Sprzedawcę. W Sklepie Internetowym dostępna jest nieodpłatna Usługa Elektroniczna: Formularz Zamówienia.</li>
            <li>Sklep Internetowy nie służy do sprzedaży hurtowej. W przypadku chęci zakupu Usług w ilości hurtowej Klient, powinien skontaktować się ze Sprzedawcą drogą email wysyłając zapytanie na Adres Elektroniczny Sprzedawcy: <a href="mailto:renewal@ruptela.com">renewal@ruptela.com</a></li>
            <li>Informacje o Usługach zamieszczone na stronach Sklepu Internetowego nie stanowią oferty w rozumieniu przepisów kodeksu cywilnego, lecz zaproszenie do składania ofert w rozumieniu art. 71 kodeksu cywilnego.</li>
            <li>Usługi nie podlegają gwarancji.</li>
            <li>Sprzedawca zastrzega sobie prawo do zmiany Ceny Usługi prezentowanej w Sklepie Internetowym, wprowadzenia nowych Usług do oferty, usuwania Usług z oferty lub zmianę ich opisu, prowadzenia akcji promocyjnych w Sklepie Internetowym, w szczególności na podstawie regulaminu danej promocji. Wprowadzone zmiany nie wpływają na skuteczność i wykonywanie Zamówień złożonych wcześniej przez Klienta.</li>
            <li>Sprzedawca zapewnia dostępność Sklepu Internetowego dla Klienta wyłącznie on-line i pod warunkiem zapewnienia przez Klienta na jego własny koszt:
              <ul>
                <li>sprzętu komputerowego z systemem operacyjnym pozwalającym na korzystanie z zasobów sieci Internet,</li>
                <li>połączenia z siecią Internet;</li>
                <li>dostępu do indywidualnego konta poczty elektronicznej e-mail;</li>
                <li>prawidłowo skonfigurowanej przeglądarki internetowej w najnowszej oficjalnej wersji z włączoną opcją obsługi plików typu „cookies” (np. Mozilla Firefox, Google Chrome, Safari, Opera).</li>
              </ul>
            </li>
            <li>Z zastrzeżeniem odmiennych postanowień Regulaminu, Sklep Internetowy jest dostępny dla Klienta w okresie korzystania przez niego ze Sklepu Internetowego, przez całą dobę i siedem dni w tygodniu.</li>
            <li>Sprzedawca zastrzega możliwość zaistnienia niedostępności on-line Sklepu Internetowego dla Klienta celem zapewnienia bezpieczeństwa i stabilności Sklepu Internetowego na potrzeby napraw, konserwacji, usuwania awarii, wprowadzania niezbędnych adaptacji, zmian i innych podobnych czynności.</li>
            <li>Sprzedawca zastrzega sobie prawo do zmiany funkcjonalności Sklepu Internetowego, zarówno poprzez rozbudowywanie go o nowe funkcje jak i zmiany istniejącej funkcjonalności.</li>
          </ul>
        </div>
        <div id="Zamówienia i ich realizacja">
          <li>
            <a href="#Zamówienia i ich realizacja">ZAMÓWIENIA I ICH REALIZACJA</a>
          </li>
          <ul>
            <li>Zamówienia można składać za pośrednictwem elektronicznego Formularza zamówienia dostępnego w Sklepie Internetowym, przez całą dobę, 7 dni w tygodniu.</li>
            <li>Klient może złożyć Zamówienie poprzez podanie danych wskazanych w elektronicznym Formularzu zamówienia niezbędnych do realizacji Zamówienia.</li>
            <li>Celem złożenia Zamówienia za pośrednictwem Sklepu Internetowego Klient podaje Business ID Urządzenia OBU jakiego dotyczy odnowienie Abonamentu e-TOLL oraz podejmuje inne czynności techniczne w oparciu o wyświetlane Klientowi komunikaty, w tym formę płatności. Następnie Klient składa Zamówienie przez wysłanie elektronicznego Formularza zamówienia do Sprzedawcy, wybierając na stronie internetowej Sklepu Internetowego przycisk <strong>„Dalej”</strong> który jest tym samym potwierdzeniem przyjęcia do wiadomości obowiązku zapłaty. W celu złożenia Zamówienia konieczna jest uprzednia akceptacja postanowień Regulaminu przez Klienta oraz wpisanie adresu e-mail Klienta. Na każdym etapie składania Zamówienia, Klient widzi informację o głównych cechach zamawianych Usług tj. łącznym koszcie Zamówienia i Cenie za wybraną Usługę jak też o wszystkich dodatkowych kosztach, jakie go obciążają w związku z Zamówieniem.</li>
            <li>Dokonanie płatności rzez Klienta stanowi złożenie Sprzedawcy oferty zawarcia Umowy sprzedaży Usług będących przedmiotem Zamówienia.</li>
            <li>Po dokonaniu płatności za złożone zamówienie zapłaty, Sprzedawca przesyła na podany przez Klienta Adres Elektroniczny informację o realizacji Zamówienia tj. przedłużeniu na kolejne 12 miesięcy Abonamentu e-TOLL. Informacja ta stanowi oświadczenie Sprzedawcy o przyjęciu oferty, co jest równoznaczne z zawarciem przez Strony Umowy sprzedaży.</li>
            <li>Wiążąca i ostateczna jest Cena podana w chwili składania Zamówienia przez Klienta.</li>
            <li>W związku z realizacją Zamówienia, Sprzedawcy przysługuje wynagrodzenie według ceny widocznej w Formularzu zamówienia obowiązującym w momencie składania Zamówienia.</li>
            <li>Ceny podane w Sklepie internetowym wyrażone są w złotych, są cenami brutto i zawierają wszystkie składniki, w tym podatek VAT oraz cła, chyba że obowiązujące przepisy uprawniają Sprzedawcę do nienaliczania podatku VAT wobec określonego Klienta zidentyfikowanego numerem NIP kraju siedziby Klienta.</li>
            <li>O całkowitej cenie za Usługi objęte Zamówieniem, która uwzględnia koszty dostawy, Kupujący informowany jest przed dokonaniem płatności za Zamówienie.</li>
            <li>Za Zamówienie złożone w Sklepie Internetowym Klient może zapłacić jedynie z góry – za pośrednictwem operatora płatności: <a href="https://przelewy24.pl" target="_blank" rel="noreferrer">przelewy24.pl</a>.</li>
            <li>Klient dokonując płatności zostanie poinformowany o jej wysokości oraz o danych operatora realizującego płatności bezpośrednio przed jej dokonaniem. Szczegółowy regulamin dokonywania płatności za pośrednictwem operatorów realizujących płatności elektroniczne dostępny jest na stronie internetowej danego operatora.</li>
            <li>Sprzedawca świadczy Usługę w rozumieniu niniejszego regulaminu wyłącznie na terytorium Rzeczpospolitej Polskiej, chyba że inaczej zastrzeżono w opisie danej Usługi.</li>
            <li>Usługa jest świadczona przez Sprzedającego automatycznie w chwili dokonania przez Klienta płatności.</li>
            <li>Po złożeniu i opłaceniu Zamówienia przez Klienta, Sprzedawca potwierdza jego realizację poprzez przesłanie przez Sprzedawcę Klientowi stosownej wiadomości e-mail na podany w trakcie składania Zamówienia adres poczty elektronicznej Klienta.  Potwierdzenie realizacji Zamówienia przez Sprzedawcę jest potwierdzeniem zawarcia Umowy sprzedaży przez Strony.</li>
            <li>Klient oświadcza, że wyraża zgodę na stosowanie i przekazywanie przez Sprzedawcę faktur elektronicznych niewymagających podpisów stron w formacie pdf, poprzez wysyłanie ich na wskazany przez Klienta Adres Elektroniczny. Sprzedawca dostarcza Klientowi fakturę drogą elektroniczną niezwłocznie po otrzymaniu płatności za Zamówienie.</li>
            <li>Minimalny czas trwania zobowiązań Klienta wynikających z Umowy to czas realizacji Umowy Sprzedaży, tj. dokonania zapłaty i odbioru Usługi.</li>
            <li>Korzystanie ze Sklepu przez Klienta nie łączy się z obowiązkiem złożenia kaucji lub udzielenia innych gwarancji finansowych.</li>
            <li>Sklep nie zapewnia usług posprzedażnych.</li>
          </ul>
        </div>
        <div id="Rozwiązanie i wygaśniecie umowy o świadczenie usług">
          <li>
            <a href="#Rozwiązanie i wygaśniecie umowy o świadczenie usług">ROZWIĄZANIE I WYGAŚNIĘCIE UMOWY O ŚWIADCZENIE USŁUG</a>
          </li>
          <ul>
            <li>Niniejszy rozdział nie dotyczy Umów Sprzedaży, lecz ma zastosowanie wyłącznie do umów o świadczenie drogą elektroniczną usług na rzecz Klienta.</li>
            <li>Umowa o świadczenie usług drogą elektroniczną może zostać rozwiązana przez Klienta w każdym czasie i bez podania przyczyny, poprzez wysłanie stosownego żądania do Usługodawcy, w szczególności za pośrednictwem poczty elektronicznej na adres: <a href="mailto:biuro@linqo.pl">biuro@linqo.pl</a> lub też pisemnie na adres: <strong>LINQO Sp. z o.o. ul. Podwale 1/30, 50-043 Wrocław</strong>. Usługodawca dokona potwierdzenia rozwiązania umowy poprzez wysłanie Klientowi wiadomości na Adres Elektroniczny wskazany przez Usługobiorcę.</li>
            <li>Strony mogą rozwiązać umowę o świadczenie Usługi Elektronicznej w każdym czasie w drodze porozumienia stron.</li>
            <li>Usługodawca ma prawo wypowiedzieć umowę o świadczenie usług drogą elektroniczną w każdym czasie z ważnych przyczyn za 14-dniowym okresem wypowiedzenia. Usługodawca wypowiada umowę o świadczenie usług drogą elektroniczną poprzez wysłanie do Klienta stosownego oświadczenia woli na Adres Elektroniczny podany przez Klienta w trakcie składania Zamówienia.</li>
          </ul>
        </div>
        <div id="Ochrona danych osobowych">
          <li>
            <a href="#Ochrona danych osobowych">OCHRONA DANYCH OSOBOWYCH</a>
          </li>
          <ul>
            <li>Sprzedawca jako Administrator Danych Osobowych przetwarza dane osobowe Klienta na podstawie zgody oraz w związku z prawnie uzasadnionymi interesami Sprzedawcy.</li>
            <li>Wyrażenie przez Klienta zgody na przetwarzanie danych osobowych jest dobrowolne, a zgoda na przetwarzanie danych w określonym celu może zostać w każdej chwili wycofana.</li>
            <li>Sprzedawca jako Administrator Danych Osobowych zbiera i przetwarza dane osobowe wyłącznie w zakresie jaki jest to uzasadnione umownym lub prawnym obowiązkiem.</li>
            <li>Sprzedawca jako Administrator Danych Osobowych dokłada wszelkich starań, aby zapewnić wszelkie możliwe środki fizycznej, technicznej i organizacyjnej ochrony Danych Osobowych przed ich przypadkowym czy umyślnym zniszczeniem, przypadkową utratą, zmianą, nieuprawnionym ujawnieniem, wykorzystaniem czy dostępem, zgodnie ze wszystkimi obowiązującymi przepisami prawa, w tym RODO.</li>
            <li>Sprzedawca korzysta w ramach Sklepu Internetowego z plików cookies i innych podobnych technologii, żeby poprawić efektywność i zaproponować Klientowi coraz doskonalsze funkcjonalności Sklepu Internetowego. Jeśli Klient nie zgadza się na zapisywanie i odbieranie informacji w plikach cookies, może on zmienić zasady dotyczące plików cookies za pomocą ustawień swojej przeglądarki internetowej lub zastosowanie tzw. opt-out na stronie dostawcy danego rozwiązania technologicznego. Szczegółowe informacje odnośnie stosowanych przez Sprzedawcę technologii dostępne są w Polityce cookies dostępnej na stronie internetowej: <a target="_blank" rel="noreferrer" href="https://linqo.pl/polityka-prywatnosci/">https://linqo.pl/polityka-prywatnosci/</a></li>
            <li>Więcej informacji na temat przetwarzania Danych Osobowych przez Sprzedawcę znajduje się w Polityce Prywatności dostępnej na stronie internetowej: <a target="_blank" rel="noreferrer" href="https://linqo.pl/polityka-prywatnosci/">https://linqo.pl/polityka-prywatnosci/</a>.</li>
          </ul>
        </div>
        <div id="Odpowiedzialność">
          <li>
            <a href="#Odpowiedzialność">ODPOWIEDZIALNOŚĆ</a>
          </li>
          <ul>
            <li>Klient zobowiązany jest korzystać ze Sklepu Internetowego zgodnie z jego przeznaczeniem i powstrzymać się od wszelkiej działalności, która mogłaby zakłócić jego prawidłowe funkcjonowanie.</li>
            <li>Klientowi zakazuje się wprowadzania do obszaru Sklepu Internetowego treści o charakterze bezprawnym. Klient ponosi pełną odpowiedzialność za poprawność, zakres, kompletność, treść i zgodność z przepisami prawa wprowadzonych danych.</li>
            <li>W zakresie dozwolonym przepisami prawa, Sprzedawca nie ponosi odpowiedzialności za:
              <ul>
                <li>blokowanie przez administratorów serwerów pocztowych, które obsługują wiadomości e-mail Klienta, przesyłane wiadomości Sprzedawcy na Adres Elektroniczny Klienta oraz za usuwanie i blokowanie wiadomości e-mail wysłanych przez Sprzedającego przez oprogramowanie zainstalowane na sprzęcie komputerowym używanym przez Klienta;</li>
                <li>nieprawidłowe działanie Sklepu Internetowego wynikające z okoliczności, że sprzęt komputerowy, oprogramowanie lub dostęp do Internetu Klienta nie spełniają wymagań technicznych wskazanych w Regulaminie;</li>
                <li>konsekwencje podania przez Klienta błędnych lub niezgodnych z prawdą danych Klienta przy lub składaniu Zamówienia.</li>
              </ul>
            </li>
            <li>Sprzedawca zastrzega sobie prawo umieszczania w dowolnej części Sklepu Internetowego treści reklamowych w formach stosowanych w Internecie. Sprzedawca nie ponosi odpowiedzialności za treści reklamowe zamieszczane w Sklepie Internetowym oraz za wynikające z tego tytułu roszczenia osób trzecich.</li>
          </ul>
        </div>
        <div id="Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń
          oraz zasady dostępu do tych procedur">
          <li>
            <a href="#Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń
          oraz zasady dostępu do tych procedur">POZASĄDOWE SPOSOBY ROZPATRYWANIA REKLAMACJI I DOCHODZENIA ROSZCZEŃ
            ORAZ ZASADY DOSTĘPU DO TYCH PROCEDUR</a>
          </li>
          <ul>
            <li>Szczegółowe informacje dotyczące możliwości skorzystania przez Klienta będącego konsumentem z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń oraz zasady dostępu do tych procedur dostępne są na stronie internetowej Urzędu Ochrony Konkurencji i Konsumentów pod adresem:<br/><a target="_blank" rel="noreferrer" href="https://uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php">https://uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php</a>.</li>
            <li>Konsument posiada następujące przykładowe możliwości skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń:
              <ul>
                <li>Konsument uprawniony jest do zwrócenia się do stałego polubownego sądu konsumenckiego, o którym mowa w art. 37 ustawy z dnia 15 grudnia 2000 r. o Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z późn. zm.) z wnioskiem o rozstrzygnięcie sporu wynikłego z Umowy zawartej ze Sprzedawcą (więcej informacji na stronie: <a target="_blank" rel="noreferrer" href="http://www.spsk.wiih.org.pl/">http://www.spsk.wiih.org.pl/</a>).</li>
                <li>Konsument uprawniony jest do zwrócenia się do wojewódzkiego inspektora Inspekcji Handlowej, zgodnie z art. 36 ustawy z dnia 15 grudnia 2000 r. o Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z późn. zm.) z wnioskiem o wszczęcie postępowania mediacyjnego w sprawie polubownego zakończenia sporu między Konsumentem a Sprzedawcą (więcej informacji na stronie inspektora właściwego ze względu na miejsce wykonywania działalności gospodarczej przez Sprzedawcę).</li>
                <li>Konsument może uzyskać bezpłatną pomoc w sprawie rozstrzygnięcia sporu między nim a Sprzedawcą, korzystając także z bezpłatnej pomocy powiatowego (miejskiego) rzecznika konsumentów lub organizacji społecznej, do której zadań statutowych należy ochrona konsumentów (m.in. Federacja Konsumentów, Stowarzyszenie Konsumentów Polskich). Porady udzielane są między innymi mailowo pod adresem <a href="mailto:porady@dlakonsumentow.pl">porady@dlakonsumentow.pl</a> oraz pod numerem infolinii konsumenckiej <a href="tel:801 440 220">801 440 220</a> (infolinia czynna w dni robocze, w godzinach 8:00 - 18:00, opłata za połączenie wg taryfy operatora). Szczegółowe informacje dotyczące możliwości skorzystania przez Konsumenta z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń oraz zasady dostępu do tych procedur dostępne są w siedzibach oraz na stronach internetowych powiatowych (miejskich) rzeczników konsumentów, organizacji społecznych, do których zadań statutowych należy ochrona konsumentów, Wojewódzkich Inspektoratów Inspekcji Handlowej oraz pod następującymi adresami internetowymi Urzędu Ochrony Konkurencji i Konsumentów: <a target="_blank" rel="noreferrer" href="http://www.uokik.gov.pl/spory_konsumenckie.php">http://www.uokik.gov.pl/spory_konsumenckie.php</a>; <a target="_blank" rel="noreferrer" href="http://www.uokik.gov.pl/sprawy_indywidualne.php">http://www.uokik.gov.pl/sprawy_indywidualne.php</a> oraz <a target="_blank" rel="noreferrer" href="http://www.uokik.gov.pl/wazne_adresy.php">http://www.uokik.gov.pl/wazne_adresy.php</a>.</li>
              </ul>
            </li>
            <li>Przy Prezesie Urzędu Ochrony Konkurencji i Konsumentów działa także punkt kontaktowy (telefon: <a href="tel:22 55 60 333">22 55 60 333</a>, email: <a href="mailto:kontakt.adr@uokik.gov.pl">kontakt.adr@uokik.gov.pl</a> lub adres pisemny: Pl. Powstańców Warszawy 1, Warszawa.), którego zadaniem jest między innymi udzielanie pomocy konsumentom w sprawach dotyczących pozasądowego rozwiązywania sporów konsumenckich.</li>
            <li>Pod adresem <a target="_blank" rel="noreferrer" href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a> dostępna jest platforma internetowego systemu rozstrzygania sporów pomiędzy konsumentami i przedsiębiorcami na szczeblu unijnym (platforma ODR). Platforma ODR stanowi interaktywną i wielojęzyczną stronę internetową z punktem kompleksowej obsługi dla konsumentów i przedsiębiorców dążących do pozasądowego rozstrzygnięcia sporu dotyczącego zobowiązań umownych wynikających z internetowej umowy sprzedaży lub umowy o świadczenie usług (więcej informacji na stronie samej platformy lub pod adresem internetowym Urzędu Ochrony Konkurencji i Konsumentów: <a target="_blank" rel="noreferrer" href="https://uokik.gov.pl/spory_konsumenckie_faq_platforma_odr.php">https://uokik.gov.pl/spory_konsumenckie_faq_platforma_odr.php</a>).</li>
            <li>Sprzedawca informuje Klienta będącego Przedsiębiorcą z uprawnieniami Konsumenta o możliwości skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń wskazanych w niniejszym punkcie Regulaminu - <strong>POZASĄDOWE SPOSOBY ROZPATRYWANIA REKLAMACJI I DOCHODZENIA ROSZCZEŃ ORAZ ZASADY DOSTĘPU DO TYCH PROCEDUR</strong>.</li>
          </ul>
        </div>
        <div id="Odstąpienie od umowy sprzedaży">
          <li>
            <a href="#Odstąpienie od umowy sprzedaży">ODSTĄPIENIE OD UMOWY SPRZEDAŻY</a>
          </li>
          <ul>
            <li>Informacje dotyczące prawa odstąpienia od Umowy sprzedaży zostały zawarte w załączniku nr 3 do Regulaminu.</li>
          </ul>
        </div>
        <div
          id="Postanowienia dotyczące Klientów będących Przedsiębiorcami"
          className={styles.provisions_applicable_to_business_customers}
        >
          <li>
            <a href="#Postanowienia dotyczące Klientów będących Przedsiębiorcami">POSTANOWIENIA DOTYCZĄCE KLIENTÓW BĘDĄCYCH PRZEDSIĘBIORCAMI</a>
          </li>
          <ul>
            <li>Przedsiębiorca będący osobą fizyczną prowadzący jednoosobową działalność gospodarczą jest objęty ochroną przewidzianą Ustawą o prawach konsumenta, pod warunkiem, że Umowa Sprzedaży, którą zawiera ze Sprzedawcą, nie ma charakteru zawodowego.</li>
            <li>Osoba prowadząca działalność gospodarczą, o której mowa w pkt 1 niniejszego paragrafu jest objęta ochroną wyłącznie w zakresie:
              <ul>
                <li>niedozwolonych postanowień umownych, tzw. klauzul abuzywnych,</li>
                <li>odpowiedzialności z tytułu rękojmi za wady fizyczne i prawne Usługi,</li>
                <li>prawa odstąpienie od umowy zawartej na odległość o którym mowa w załączniku nr 3 do Regulaminu.</li>
              </ul>
            </li>
            <li>Przedsiębiorca, o którym mowa w pkt 1 niniejszego paragrafu traci uprawnienia z tytułu ochrony konsumenckiej w przypadku, gdy Umowa Sprzedaży, którą zawarł ze Sprzedawcą posiada charakter zawodowy, który jest weryfikowany na podstawie wpisu tego przedsiębiorcy w Centralnej Ewidencji i Informacji o Działalności Gospodarczej Rzeczypospolitej Polskiej, w szczególności wskazanych tam kodów Polskiej Klasyfikacji Działalności.</li>
            <li>Przedsiębiorcy, o których mowa w pkt 1 niniejszego paragrafu nie są objęci ochroną instytucjonalną zapewnioną dla Konsumentów przez powiatowych rzeczników praw konsumenta jak również Prezesa UOKiK.</li>
          </ul>
        </div>
        <div id="Wady usługi. Reklamacje.">
          <li>
            <a href="#Wady usługi. Reklamacje.">WADY USŁUGI. REKLAMACJE.</a>
          </li>
          <ul>
            <li>Sprzedawca ma obowiązek dostarczyć Klientowi Usługę wolną od wad.</li>
            <li>Sprzedawca odpowiada wobec Klienta będącego Konsumentem lub Przedsiębiorcą z uprawnieniami Konsumenta, na zasadzie rękojmi, za wady fizyczne lub prawne zakupionej Usługi.</li>
            <li>W przypadku sprzedaży niestanowiącej sprzedaży konsumenckiej, wyłączone są przepisy zawarte w artykułach 556-576 kodeksu cywilnego.</li>
            <li>Konsument lub Przedsiębiorca z uprawnieniami Konsumenta może zgłaszać reklamacje i informacje związane z niewykonaniem lub nienależytym wykonaniem przez Sprzedawcę Umowy sprzedaży, w tym wadami Usługi, lub świadczeniem usług drogą elektroniczną określonych w Regulaminie w dowolnej formie, w tym w szczególności drogą elektroniczną na Adres Elektroniczny Sprzedawcy <a href="mailto:support@linqo.pl">support@linqo.pl</a>. Konsument lub Przedsiębiorca z uprawnieniami Konsumenta może skorzystać z wzoru formularza stanowiącego załącznik nr 1 do Regulaminu.</li>
            <li>Celem sprawnego rozpatrzenia zgłoszenia reklamacyjnego powinno ono zawierać:
              <ul>
                <li>Dane zgłaszającego reklamację (imię i nazwisko/nazwę i adres zgłaszającego, Adres Elektroniczny);</li>
                <li>Opis zdarzenia będącego podstawą reklamacji;</li>
                <li>Oczekiwania co do sposobu zaspokojenia żądania reklamacyjnego;</li>
                <li>Dowód zakupu, jeśli reklamacja dotyczy zamówionej Usługi.</li>
              </ul>
            </li>
            <li>Sprzedawca w ciągu 14 (czternastu) dni kalendarzowych od otrzymania kompletnego zgłoszenia reklamacyjnego ustosunkuje się do reklamacji Konsumenta lub Przedsiębiorcy z uprawnieniami Konsumenta i powiadomi go o dalszych działaniach związanych z rozpatrywaną reklamacją, tj. czy reklamację uwzględnia czy odrzuca. W przypadku uwzględnienia reklamacji, Sprzedawca zaakceptuje zaproponowany przez Klienta będącego Konsumentem lub Przedsiębiorcą z uprawnieniami Konsumenta sposób realizacji jego roszczeń albo przedstawi własną propozycję realizacji uprawień Klienta będącego Konsumentem lub Przedsiębiorcą z uprawnieniami Konsumenta, zgodną z przepisami prawa</li>
            <li>Konsument lub Przedsiębiorcą z uprawnieniami Konsumenta otrzyma informację o sposobie rozpatrzenia reklamacji drogą korespondencji elektronicznej, na Adres Elektroniczny podany w zgłoszeniu reklamacyjnym.</li>
          </ul>
        </div>
        <div id="Postanowienia końcowe">
          <li>
            <a href="#Postanowienia końcowe">POSTANOWIENIA KOŃCOWE</a>
          </li>
          <ul>
            <li>Sprzedawca zastrzega sobie prawo do dokonywania zmian Regulaminu z ważnych przyczyn to jest m.in.: zmiany przepisów prawa, zmiany sposobów płatności i dostaw - w zakresie, w jakim te zmiany wpływają na realizację postanowień niniejszego Regulaminu.</li>
            <li>Sprzedawca zobowiązuje się do informowania Klienta o każdorazowej zmianie Regulaminu poprzez umieszczenie jednolitego tekstu Regulaminu na stronie <a target="_blank" rel="noreferrer" href="https://sklep.id-lifting.pl/content/3-regulamin-sklepu">Link do Regulaminu sklepu</a>. Zmiany Regulaminu nie mają wpływu na zamówienia złożone przez Klienta przed wejściem w życie zmian Regulaminu – zamówienia takie są realizowane według postanowień Regulaminu obowiązującego w dacie składania zamówienia.</li>
            <li>Zmiany dokonane w Regulaminie wchodzą w życie z upływem 14 dni od momentu udostępnienia ich treści na stronie <a target="_blank" rel="noreferrer" href="https://sklep.id-lifting.pl/content/3-regulamin-sklepu">Link do Regulaminu sklepu</a>.</li>
            <li>W sprawach nieuregulowanych niniejszym Regulaminem zastosowanie będą miały odpowiednie przepisy prawa polskiego, w szczególności przepisy Ustawy z dnia 23 kwietnia 1964 r. Kodeks cywilny (tj.. Dz.U. z 2018 r. poz. 1025 ze zm.) oraz przepisy Ustawy z dnia 30 maja 2014 r. o prawach konsumenta (tekst jednolity z dnia 24 czerwca 2014 r., Dz. U. z 2014 r., poz. 827 ze zm.).</li>
            <li>W sąd właściwy miejscowo dla siedziby Sprzedawcy, a w przypadku Kupującego będącego Konsumentem lub Przedsiębiorcą z uprawnieniami Konsumenta sąd właściwy według zasad ogólnych.</li>
            <li>Niniejszy Regulamin wchodzi w życie dnia 30.01.2023</li>
          </ul>
        </div>
      </ul>
      <div>
        <span>
          Załącznik nr 1 – Informacje dotyczące korzystania z prawa odstąpienia od Umowy Sprzedaży
        </span>
        <section>
          <p>
            Prawo odstąpienia od umowy
            <br/>Mają Państwo prawo odstąpić od niniejszej umowy w terminie 14 dni bez podania jakiejkolwiek przyczyny.
            <br/>Termin do odstąpienia od umowy wygasa po upływie 14 dni od dnia zawarcia Umowy sprzedaży.
          </p>
          <p>
            Aby skorzystać z prawa odstąpienia od umowy, muszą Państwo poinformować LINQO Sp. z o.o. z siedzibą we Wrocławiu, numer NIP: 8971804573, numer REGON: 360468661, e-mail: support@linqo.pl numer telefonu: +48 71 7575444 o swojej decyzji o odstąpieniu od niniejszej umowy w drodze jednoznacznego oświadczenia (na przykład pismo wysłane pocztą lub pocztą elektroniczną).
          </p>
          <p>
            Mogą Państwo skorzystać z wzoru formularza odstąpienia od umowy, jednak nie jest to obowiązkowe. 
            <br/>Aby zachować termin do odstąpienia od umowy, wystarczy, aby wysłali Państwo informację dotyczącą wykonania przysługującego Państwu prawa odstąpienia od umowy przed upływem terminu do odstąpienia od umowy.
          </p>
          <p>
            Skutki odstąpienia od umowy
            <br/>W przypadku odstąpienia od niniejszej umowy zwracamy Państwu wszystkie otrzymane od Państwa płatności, w tym koszty dostarczenia usługi niezwłocznie, a w każdym przypadku nie później niż 14 dni od dnia, w którym zostaliśmy poinformowani o Państwa decyzji o wykonaniu prawa odstąpienia od niniejszej umowy. Zwrotu płatności dokonamy przy użyciu takich samych sposobów płatności, jakie zostały przez Państwa użyte w pierwotnej transakcji, chyba że wyraźnie zgodziliście się Państwo na inne rozwiązanie; w każdym przypadku nie poniosą Państwo żadnych opłat w związku z tym zwrotem.
            <br/>Jeżeli zażądali Państwo rozpoczęcia świadczenia usług przed upływem terminu do odstąpienia od umowy, zapłacą nam Państwo kwotę proporcjonalną do zakresu świadczeń spełnionych do chwili, w której poinformowali nas Państwo o odstąpieniu od niniejszej umowy.
          </p>
          <p>
            Check box o treści:
            <br/>Wyrażam zgodę na rozpoczęcie świadczenia usługi polegającej na polegającą na wysyłaniu, odbieraniu, przechowywaniu, przetwarzaniu oraz udostępnianiu organom Krajowej Administracji Skarbowej (dalej: KAS) danych lokalizacyjnych umożlwiających prawidłowe naliczenie opłat drogowych w systemie e-TOLL przed upływem 14 dni na odstąpienie od umowy i przyjmuję do wiadomości brak możliwości odstąpienia od umowy, gdy usługa zostanie w pełni wykonana.
          </p>
          <p>
            Check box o treści: 
            <br/>Zapoznałem się z informacją o treści: Jeżeli konsument wykonuje prawo odstąpienia od umowy po zgłoszeniu żądania rozpoczęcia świadczenia usług przed upływem terminu do odstąpienia od umowy, ma obowiązek zapłaty za świadczenia spełnione do chwili odstąpienia od umowy. Kwotę zapłaty oblicza się proporcjonalnie do zakresu spełnionego świadczenia, z uwzględnieniem uzgodnionej w umowie ceny lub wynagrodzenia. Jeżeli cena lub wynagrodzenie są nadmierne, podstawą obliczenia tej kwoty jest wartość rynkowa spełnionego świadczenia.
          </p>
        </section>
        <DownloadLink href="/files/Annex_2_pl.pdf">Załącznik nr 2</DownloadLink><br/>
        <DownloadLink href="/files/Annex_3_pl.pdf">Załącznik nr 3</DownloadLink>
      </div>
    </div>
  );
};
