import { ReactNode } from 'react';
import { useTranslation } from '../../utils/intl';
import styles from './Notes.module.scss'

interface NotesProps {
  items: ReactNode[];
}

export const Notes = ({ items }: NotesProps) => {
  const { t } = useTranslation();
  return (
    <section className={styles.notes}>
      {t('general.notes')}:
      <ul className={styles.list}>
        {items.map((value, idx) => (
          <li key={idx} className={styles.item}>
            {value}
          </li>
        ))}
      </ul>
    </section>
  );
}
