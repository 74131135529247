import styles from './Template.module.scss';
import { DownloadLink } from '../../../components/views';

export const En = () => {
  return (
    <div className={styles.termsAndConditions}>
      <div>
        <span>CONTENTS:</span>
        <ul>
          <li><a href="#General provisions">General provisions</a></li>
          <li><a href="#Definitions">Definitions</a></li>
          <li><a href="#General characteristics of the online store">General characteristics of the online store</a></li>
          <li><a href="#Orders and their execution">Orders and their execution</a></li>
          <li><a href="#Termination and expiration of the service contract">Termination and expiration of the service contract</a></li>
          <li><a href="#Protection of personal data">Protection of personal data</a></li>
          <li><a href="#Responsibility">Responsibility</a></li>
          <li><a href="#Out-of-court procedures for handling complaints and investigating claims, and rules of access to these procedures">Out-of-court procedures for handling complaints and investigating claims, and rules of access to these procedures</a></li>
          <li><a href="#Rescission from the sales contract">Rescission from the sales contract</a></li>
          <li><a href="#Provisions concerning customers who are entrepreneurs">Provisions concerning customers who are entrepreneurs</a></li>
          <li><a href="#Service Defects. Complaints.">Service Defects. Complaints.</a></li>
          <li><a href="#Final provisions">Final provisions</a></li>
        </ul>
      </div>
      <ul>
        <div id="General provisions">
          <li>
            <a href="#General provisions">GENERAL PROVISIONS</a>
          </li>
          <ul>
            <li>These Terms and Conditions of the Online Store (hereinafter referred to as <strong>"Terms and Conditions"</strong>) set out the rules for the use of the Online Store, including, among others, the placement and execution of Orders, the procedure for complaints, rights related to rescinding from the Sales Contract.</li>
            <li>These Terms and Conditions are addressed to Customers who are Consumers, who are Entrepreneurs with the rights of consumers, and to Entrepreneurs using the Online Store.</li>
            <li>The Terms and Conditions are made available to the Customer free of charge, before the conclusion of the Sales Contract, in a manner that allows obtaining, reproducing, and recording the content of the Terms and Conditions using the Information and Communication System used by the Customer.</li>
            <li>The Customer must comply with all the provisions of the Terms and Conditions. Sales are made based on the version of the Terms and Conditions that is valid and accepted by the Customer at the time of placing the Order.</li>
            <li>The conclusion of the contract for the provision of services indicated in these Terms and Conditions by electronic means shall be subject to the condition, reading, and accepting the content of these Terms and Conditions.</li>
          </ul>
        </div>
        <div id="Definitions">
          <li>
            <a href="#Definitions">DEFINITIONS</a>
          </li>
          <ul>
            <li>Whenever the Terms and Conditions refer to:
              <ul>
                <li><strong>"Business day"</strong> - means one day from Monday to Friday, excluding public holidays.</li>
                <li><strong>"Seller/Service Provider"</strong> - refers to LINQO Sp. z o.o. with its registered office in Wroclaw, Tax Identification Number (NIP): 8971804573, Business Identification Number (REGON): 360468661, entered in the Register of Entrepreneurs of the National Court Register kept by the District Court for the Capital City of Wroclaw, VI Commercial Department of the National Court Register, under KRS number 0000537198, Podwale 1/30 Street, e-mail <a href="mailto:renewal@ruptela.com">renewal@ruptela.com</a>, telephone number <a href="tel:+48 71 7575444">+48 71 7575444</a> - which operates the Online Store and sells the Services through it.</li>
                <li><strong>"Customer"</strong> - a natural person, a legal person, and an organizational unit without legal personality to which the law grants legal capacity, purchasing Services through the Online Store.</li>
                <li><strong>"Consumer"</strong> - should be understood as a consumer within Article 22<sup>1</sup> of the Civil Code, i.e., a natural person making a legal transaction with an entrepreneur that is not directly related to their business or professional activity.</li>
                <li><strong>"Entrepreneur"</strong> - should be understood as an entrepreneur within the meaning of Article 43<sup>1</sup> of the Civil Code, i.e., a natural person, a legal person, or an organizational unit without legal personality to which an act grants legal capacity, conducting business or professional activity on its behalf, concluding a Sales Contract with the Seller or using services provided by the Seller electronically.</li>
                <li><strong>"Entrepreneur with the rights of the Consumer"</strong> - should be understood as a natural person concluding a Sales Contract directly related to their business activity when it follows from the content of the Sales Contract that it does not have a professional character for this Entrepreneur, resulting in particular from the subject of the business activity, made available based on the provisions on the Central Register and Information on Business Activity, who has concluded or intends to conclude a Sales Contract with the Seller or who uses services provided by the Seller electronically.</li>
                <li><strong>"Parties"</strong> - shall be understood collectively as the Seller and the Consumer or the Seller and the Entrepreneur or the Seller and the Entrepreneur with the rights of a Consumer.</li>
                <li><strong>"Sales Contract"</strong> - should be understood as a contract concluded at a distance, the subject of which is the sale by the Seller of the Service to the Customer following the principles outlined in the Terms and Conditions.</li>
                <li><strong>"Online Store"</strong> - it should be understood as the online platform <a target="_blank" rel="noreferrer" href="https://tolls.ruptela.com">https://tolls.ruptela.com</a> administered by the Seller, available online, through which the Customer can purchase Services.</li>
                <li><strong>"Information and Communication System"</strong> - shall be understood as a set of cooperating IT devices and software, ensuring processing and storage, as well as sending and receiving data via telecommunication networks using a telecommunication terminal device appropriate for a given type of network, within the meaning of the Act of July 16, 2004. - Telecommunications Law (i.e., Journal of Laws of 2017, item 1907, as amended).</li>
                <li><strong>"Durable medium"</strong> - shall be understood as a material or tool that allows the Customer or the Seller to store information addressed personally to the Customer in a manner that allows access to the information in the future for a period of time appropriate to the purposes for which the information is used, and that allows the reproduction of the stored information in an unchanged form.</li>
                <li><strong>"Service"</strong> or <strong>"e-TOLL Subscription"</strong> - shall be understood as a service consisting of sending, receiving, storing, processing, and making available to the authorities of the National Fiscal Administration (hereinafter: KAS) location data enabling proper calculation of tolls in the e-TOLL system.</li>
                <li><strong>"Electronic Address"</strong> - it should be understood as a designation of the Information and Communication System that allows communication using electronic communication, in particular, an electronic mail;</li>
                <li><strong>"Customer"</strong> - shall mean a natural person, a legal person, or an organizational unit without legal personality, which is granted legal capacity by law, using an Electronic Service.</li>
                <li><strong>"Electronic Service"</strong> - a service provided electronically by the Service Provider to the Customer through the Site.</li>
                <li><strong>"Price"</strong> - shall mean the gross value of the Service expressed in PLN currency, including the tax on goods and services. The total cost of the Order (i.e., the price of the Service, including other costs) is indicated before the Customer places the Order.</li>
                <li><strong>"Personal Data"</strong> - shall mean information about an identified or identifiable natural person.</li>
                <li><strong>"Personal Data Controller"</strong> - should be understood as the Seller who, acting in this role alone or jointly with others, determines the purposes and means of processing Personal Data.</li>
                <li><strong>"Terms and Conditions"</strong> - shall be understood as this document.</li>
                <li><strong>"GDPR"</strong> - shall mean the REGULATION OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL (EU) 2016/679 of April 27, 2016, on the protection of natural persons concerning the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation) (OJ.EU.L.2016.119.1 of 2016.05.04).</li>
                <li><strong>"Order Form"</strong> - refers to the Electronic Service, an interactive form available on the Online Store that allows placing an Order by adding the business ID number of the OBU Device for which the Service is being purchased and specifying the terms of the Sales Contract and payment. The Order form also allows checking the validity period of the e-TOLL Subscription for the OBU Device owned by the Customer and checking the registration of the OBU Device owned by the Customer in the Service Provider's database.</li>
                <li><strong>"Order"</strong> - it should be understood as a declaration of will of the Customer, submitted via the Online Store, specifying: the type and quantity of ordered Services; type of delivery; type and amount of payment; place of delivery of items, Customer's data, constituting an offer to conclude a Sales Contract.</li>
                <li><strong>"OBU"</strong> (On Board Unit) is a GNSS-class onboard device that transmits vehicle geolocation data to the e-TOLL system using data transmission. With the OBU, it is possible to charge tolls electronically on the country's toll roads.</li>
                <li><strong>"Business ID"</strong> - A unique number assigned to the OBU Device.</li>
              </ul>
            </li>
          </ul>
        </div>
        <div id="General characteristics of the online store">
          <li>
            <a href="#General characteristics of the online store">GENERAL CHARACTERISTICS OF THE ONLINE STORE</a>
          </li>
          <ul>
            <li>The Online Store is operated by the Seller. The Online Store offers a free Electronic Service: the Order Form.</li>
            <li>The Online Store is not used for wholesale. If the Customer wishes to purchase the Services in bulk, he/she should contact the Seller via email by sending a request to the Seller's Electronic Address: <a href="mailto:renewal@ruptela.com">renewal@ruptela.com</a>.</li>
            <li>Information about the Services provided on the pages of the Online Store does not constitute an offer within the meaning of the Civil Code but an invitation to submit offers within the meaning of Article 71 of the Civil Code.</li>
            <li>Services are not subject to warranty.</li>
            <li>The Seller reserves the right to change the price of a Service presented in the Online Store, introduce new Services to the offer, remove Services from the offer or change their description, conduct promotional actions in the Online Store, in particular, based on the Terms and Conditions of a given promotion. The introduced changes do not affect the effectiveness and execution of Orders previously placed by the Customer.</li>
            <li>The Seller shall ensure that the Online Store is available to the Customer only online and provided by the Customer at his own expense:
              <ul>
                <li>computer hardware with an operating system that allows the use of Internet resources,</li>
                <li>Internet connection;</li>
                <li>access to an individual e-mail account;</li>
                <li>adequately configured web browser in the latest official version with cookies enabled (e.g., Mozilla Firefox, Google Chrome, Safari, Opera).</li>
              </ul>
            </li>
            <li>Subject to the provisions of the Terms and Conditions to the contrary, the Online Store is available to the Customer during the period of the Customer's use of the Online Store, 24 hours a day and seven days a week.</li>
            <li>The Seller reserves the possibility of online unavailability of the Online Store for the Customer to ensure the security and stability of the Online Store for repairs, maintenance, troubleshooting, making necessary adaptations, changes, and other similar activities.</li>
            <li>The Seller reserves the right to change the functionality of the Online Store, both by expanding it with new features and changing the existing functionality.</li>
          </ul>
        </div>
        <div id="Orders and their execution">
          <li>
            <a href="#Orders and their execution">ORDERS AND THEIR EXECUTION</a>
          </li>
          <ul>
            <li>Orders can be placed via an electronic Order Form available on the Online Store, 24 hours a day, 7 days a week.</li>
            <li>The Customer may place an Order by providing the data indicated in the electronic Order Form necessary for the execution of the Order.</li>
            <li>To place an Order via the Online Store, the Customer provides the Business ID of the OBU to which the renewal of the e-TOLL Subscription applies and takes other technical actions based on the messages displayed to the Customer, including the form of payment. The Customer then submits the Order by sending an electronic Order Form to the Vendor, selecting the <strong>"Next"</strong> button on the Online Store's website, thus confirming acceptance of the payment obligation. To place an Order, it is necessary for the Customer to accept the provisions of the Terms and Conditions in advance and to enter the Customer's e-mail address. At each stage of placing the Order, the Customer sees information about the main features of the ordered Services, i.e., the total cost of the Order and the Price for the selected Service, as well as all additional fees charged to them in connection with the Order.</li>
            <li>Making payment by the Customer constitutes an offer to the Seller to conclude a Sales Contract for the Services that are the subject of the Order.</li>
            <li>After the payment for the placed payment order is made, the Seller sends to the Electronic Address provided by the Customer information about the execution of the Order, i.e., an extension of the e-TOLL Subscription for another 12 months. This information constitutes the Seller's statement of acceptance of the offer, which is tantamount to the conclusion of the Contract of Sale by the Parties.</li>
            <li>The Price quoted when placing the Order by the Customer is binding and final.</li>
            <li>In connection with the fulfillment of the Order, the Seller shall be entitled to remuneration according to the price shown in the Order Form in effect at the time of placing the Order.</li>
            <li>Prices quoted in the Online Store are expressed in PLN, are gross prices, and include all components, including VAT and customs duties, unless applicable regulations authorize the Seller not to charge VAT to a specific customer identified by the VAT number of the customer's country of residence.</li>
            <li>The Buyer is informed of the total price for the Services covered by the Order, which includes delivery costs, before payment for the Order is made.</li>
            <li>For an Order placed in the Online Store, the Customer can only pay in advance - through the payment operator: <a target="_blank" rel="noreferrer" href="https://przelewy24.pl/">przelewy24.pl</a>.</li>
            <li>When making a payment, the customer will be informed of the payment amount and the details of the payment processing operator immediately before the payment is made. Detailed regulations for making payments through electronic payment operators are available on the operator’s website.</li>
            <li>The Seller provides the Service within the meaning of these terms and conditions exclusively in the territory of the Republic of Poland unless otherwise stipulated in the description of a given Service.</li>
            <li>The Seller provides service automatically when the Customer makes payment.</li>
            <li>Once the Order has been placed and paid for by the Customer, the Seller confirms its execution by sending the Customer an appropriate e-mail message to the Customer's e-mail address provided during the placement of the Order. Confirmation of the execution of the Order by the Seller is confirmation of the conclusion of the Contract of Sale by the Parties.</li>
            <li>The Customer declares that he agrees to the use and transmission by the Seller of electronic invoices, not requiring signatures of the parties in pdf format, by sending them to the Electronic Address indicated by the Customer. The Seller shall provide the Customer with an electronic invoice immediately upon receipt of payment for the Order.</li>
            <li>The minimum duration of the Customer's obligations under the Contract is the time of execution of the Sales Contract, i.e., payment and receipt of the Service.</li>
            <li>The use of the Store by the Customer is not linked to the obligation to make a deposit or provide other financial guarantees.</li>
            <li>The store does not provide after-sales services.</li>
          </ul>
        </div>
        <div id="Termination and expiration of the service contract">
          <li>
            <a href="#Termination and expiration of the service contract">TERMINATION AND EXPIRATION OF THE SERVICE CONTRACT</a>
          </li>
          <ul>
            <li>This section does not apply to Sales Contracts but only contracts for the electronic provision of services to the Customer.</li>
            <li>The Client may terminate the agreement for the provision of services by electronic means at any time and without giving any reason by sending an appropriate request to the Service Provider, in particular via e-mail to: <a href="mailto:biuro@linqo.pl">biuro@linqo.pl</a> or in writing to the address: <strong>Linqo Sp. z o.o. 1/30 Podwale Street, 50-043 Wrocław</strong>. The Service Provider will confirm the termination of the contract by sending the Client a message to the Electronic Address indicated by the Client.</li>
            <li>The parties may terminate the contract for Electronic Services at any time by mutual agreement.</li>
            <li>The Service Provider has the right to terminate the contract for providing services electronically at any time for valid reasons upon 14 days' notice. The Service Provider shall terminate the contract for the provision of services by electronic means by sending the appropriate statement of intent to the Client at the Electronic Address provided by the Client in the course of placing the Order.</li>
          </ul>
        </div>
        <div id="Protection of personal data">
          <li>
            <a href="#Protection of personal data">PROTECTION OF PERSONAL DATA</a>
          </li>
          <ul>
            <li>The Seller, as a Personal Data Administrator, processes the Customer's personal data based on consent and in connection with the legitimate interests of the Seller.</li>
            <li>The Customer's consent to the processing of personal data is voluntary, and consent to the processing of data for a specific purpose may be withdrawn at any time.</li>
            <li>The Seller, as a Personal Data Administrator, collects and processes personal data only to the extent that it is justified by contractual or legal obligation.</li>
            <li>The Vendor, as a Personal Data Administrator, shall use its best efforts to ensure all possible physical, technical and organizational measures to protect Personal Data against accidental or intentional destruction, accidental loss, alteration, unauthorized disclosure, use, or access, following all applicable laws, including the GDPR.</li>
            <li>The Seller uses cookies and other similar technologies within the Online Store to improve the efficiency and offer the Customer increasingly enhanced functionalities of the Online Store. If the Customer does not agree to save and receive information in cookies, he/she may change the settings regarding cookies through the settings of the web browser or the use of the so-called opt-out on the website of the provider of the given technical solution. Detailed information regarding the technologies used by the Seller is available in the Cookie Policy on the website: <a target="_blank" rel="noreferrer" href="https://linqo.eu/privacy-policy/">https://linqo.eu/privacy-policy/</a>.</li>
            <li>For more information on the Seller's processing of Personal Data, please refer to the Privacy Policy available at: <a target="_blank" rel="noreferrer" href="https://linqo.eu/privacy-policy/">https://linqo.eu/privacy-policy/</a>.</li>
          </ul>
        </div>
        <div id="Responsibility">
          <li>
            <a href="#Responsibility">RESPONSIBILITY</a>
          </li>
          <ul>
            <li>The customer is obliged to use the Online Store in accordance with its purpose and refrain from any activity that could interfere with its proper functioning.</li>
            <li>The Customer is prohibited from entering unlawful content into the area of the Online Store. The Customer shall bear full responsibility for the correctness, scope, completeness, content, and compliance with legal regulations of the entered data.</li>
            <li>To the extent permitted by law, the Seller shall not be liable for:
              <ul>
                <li>blocking by the administrators of the mail servers that handle the Customer's e-mails sent by the Seller to the Customer's Electronic Address, and for deleting and blocking e-mails sent by the Seller by software installed on the computer hardware used by the Customer;</li>
                <li>malfunction of the Online Store resulting from the circumstance that the Customer's computer equipment, software, or Internet access do not meet the technical requirements indicated in the Terms and Conditions;</li>
                <li>The consequences of the Customer providing erroneous or untrue Customer data when placing an Order.</li>
              </ul>
            </li>
            <li>The Seller reserves the right to place advertising content in any part of the Online Store in the forms used on the Internet. However, the Seller shall not be liable for advertising content in the Online Store and any claims of third parties arising therefrom.</li>
          </ul>
        </div>
        <div id="Out-of-court procedures for handling complaints and investigating claims, and rules of access to these procedures">
          <li>
            <a href="#Out-of-court procedures for handling complaints and investigating claims, and rules of access to these procedures">OUT-OF-COURT PROCEDURES FOR HANDLING COMPLAINTS AND INVESTIGATING CLAIMS, AND RULES OF ACCESS TO THESE PROCEDURES</a>
          </li>
          <ul>
            <li>Detailed information on the possibility for a customer who is a consumer how to use out-of-court procedures for handling complaints and pursuing claims, as well as the rules of access to these procedures, is available on the website of the Office of Competition and Consumer Protection at:<br/><a target="_blank" rel="noreferrer" href="https://uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php">https://uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php</a>.</li>
            <li>The consumer has the following examples of out-of-court means of complaint handling and redress:
              <ul>
                <li>A consumer is entitled to apply to a permanent amicable consumer court referred to in Article 37 of the Act of December 15, 2000, on Trade Inspection (Journal of Laws of 2014, item 148, as amended) to resolve a dispute arising from the Agreement concluded with the Seller (for more information, see: <a target="_blank" rel="noreferrer" href="http://www.spsk.wiih.org.pl/">http://www.spsk.wiih.org.pl/</a>).</li>
                <li>The Consumer is entitled to apply to the provincial inspector of the Commercial Inspection, by Article 36 of the Act of December 15, 2000, on Commercial Inspection (Journal of Laws of 2014, item 148, as amended) with a request to initiate mediation proceedings for the amicable termination of a dispute between the Consumer and the Seller (for more information, see the website of the inspector competent for the Seller's place of business).</li>
                <li>A consumer may obtain free assistance in resolving a dispute between him and the Seller, using free service from a district (city) consumer ombudsman or a social organization whose statutory tasks include consumer protection (such as the Consumer Federation, the Association of Polish Consumers). Advice is provided, among other things, by e-mail at <a href="mailto:porady@dlakonsumentow.pl">porady@dlakonsumentow.pl</a> and at the consumer hotline number <a href="tel:801 440 220">801 440 220</a> (the hotline is open on working days, from 8:00 a.m. to 6:00 p.m., call charge according to the operator's tariff). In addition, detailed information on the consumer's ability to use out-of-court procedures for handling complaints and pursuing claims, as well as rules of access to these procedures, are available at the offices and websites of the county (city) consumer ombudspersons, social organizations whose statutory tasks include protecting consumers, Provincial Inspectorates of Commercial Inspection and at the following web addresses of the Office of Competition and Consumer Protection: <a target="_blank" rel="noreferrer" href="http://www.uokik.gov.pl/spory_konsumenckie.php">http://www.uokik.gov.pl/spory_konsumenckie.php</a>; <a target="_blank" rel="noreferrer" href="http://www.uokik.gov.pl/sprawy_indywidualne.php">http://www.uokik.gov.pl/sprawy_indywidualne.php</a> and <a target="_blank" rel="noreferrer" href="http://www.uokik.gov.pl/wazne_adresy.php">http://www.uokik.gov.pl/wazne_adresy.php</a>.</li>
              </ul>
            </li>
            <li>There is also a contact point at the President of the Office of Competition and Consumer Protection (telephone: <a href="tel:22 55 60 333">22 55 60 333</a>, email: <a href="mailto:kontakt.adr@uokik.gov.pl">kontakt.adr@uokik.gov.pl</a> or written address: Pl. Powstańców Warszawy 1, Warsaw.), whose task is, among other things, to assist consumers in matters concerning the out-of-court settlement of consumer disputes.</li>
            <li>A platform for online dispute resolution between consumers and businesses at the EU level (ODR platform) is available at <a target="_blank" rel="noreferrer" href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a>. The ODR platform is an interactive and multilingual website with a one-stop shop for consumers and businesses seeking out-of-court resolution of disputes concerning contractual obligations arising from an online sales or service contract (for more information, visit the platform itself or the Office of Competition and Consumer Protection's website address: <a target="_blank" rel="noreferrer" href="https://uokik.gov.pl/spory_konsumenckie_faq_platforma_odr.php">https://uokik.gov.pl/spory_konsumenckie_faq_platforma_odr.php</a>).</li>
            <li>The Seller informs the Customer, who is an Entrepreneur with the rights of a Consumer, about the possibility of using the <strong>out-of-court</strong> ways of dealing with complaints and claims indicated in this section of the Terms <strong>and Conditions - OUT OF COURT PROCEDURES FOR CASE CLAIMS AND RECEIVING CLAIMS AND RULES FOR ACCESS TO THESE PROCEDURES</strong>.</li>
          </ul>
        </div>
        <div id="Rescission from the sales contract">
          <li>
            <a href="#Rescission from the sales contract">RESCISSION FROM THE SALES CONTRACT</a>
          </li>
          <ul>
            <li>Information on the right of rescission from the Sales Contract is included in Appendix 1 to the Terms and Conditions.</li>
          </ul>
        </div>
        <div
          id="Provisions concerning customers who are entrepreneurs"
          className={styles.provisions_applicable_to_business_customers}
        >
          <li>
            <a href="#Provisions concerning customers who are entrepreneurs">PROVISIONS CONCERNING CUSTOMERS WHO ARE ENTREPRENEURS</a>
          </li>
          <ul>
            <li>An entrepreneur who is a sole proprietor is covered by the protection of the Consumer Rights Act, provided that the Sales Contract he concludes with the Seller is not of a professional nature.</li>
            <li>The business person referred to in paragraph 1 of this section is covered only to the extent:
              <ul>
                <li>prohibited contractual provisions, so-called abusive clauses,</li>
                <li>warranty liability for physical and legal defects in the Service,</li>
                <li>The right of rescission from a contract concluded at a distance referred to in Appendix No. 1 to the Terms and Conditions.</li>
              </ul>
            </li>
            <li>The entrepreneur referred to in item 1 of this paragraph loses his rights under consumer protection if the Sales Contract he has concluded with the Seller has a professional character, which is verified based on the entry of this entrepreneur in the Central Register and Information on Business Activity of the Republic of Poland, in particular, the codes of the Polish Classification of Business Activities indicated there.</li>
            <li>Entrepreneurs referred to in paragraph 1 of this section are not covered by the institutional protection provided for Consumers by county consumer ombudspersons and the President of the OCCP.</li>
          </ul>
        </div>
        <div id="Service Defects. Complaints.">
          <li>
            <a href="#Service Defects. Complaints.">SERVICE DEFECTS. COMPLAINTS.</a>
          </li>
          <ul>
            <li>The Seller must provide the Customer with the Service free of defects.</li>
            <li>The Seller shall be liable to the Customer, who is a Consumer or Entrepreneur with the rights of a Consumer, on a warranty basis, for physical or legal defects in the purchased Service.</li>
            <li>In the case of sales that do not constitute consumer sales, the provisions of articles 556-576 of the Civil Code are excluded.</li>
            <li>A Consumer or an Entrepreneur with the rights of a Consumer may file complaints and information related to non-performance or improper performance of the Sales Contract by the Seller, including defects in the Service or provision of electronic services specified in the Terms and Conditions in any form, including, in particular, electronically to the Seller's Electronic Address <a href="mailto:support@linqo.pl">support@linqo.pl</a>. A Consumer or Entrepreneur with the rights of a Consumer may use the complaint form attached as Appendix 2 to the Terms and Conditions.</li>
            <li>To process the claim efficiently, it should include the following:
              <ul>
                <li>Details of the claimant (name and address of the claimant, Electronic Address);</li>
                <li>Description of the event giving rise to the complaint;</li>
                <li>Expectations as to how the complaint request will be satisfied;</li>
                <li>Proof of purchase if the complaint is about the ordered Service.</li>
              </ul>
            </li>
            <li>The Seller shall, within 14 (fourteen) calendar days of receipt of a complete complaint notification, respond to the complaint of the Consumer or Entrepreneur with Consumer rights and notify him of further actions related to the complaint under consideration, i.e., whether he accepts or rejects the complaint. If the complaint is approved, the Seller will accept the method proposed by the Customer, who is a Consumer or Entrepreneur with the rights of a Consumer for the realization of his claims, or will present its proposal for the realization of the rights of the Customer who is a Consumer or Entrepreneur with the rights of a Consumer, consistent with the provisions of law.</li>
            <li>A Consumer or an Entrepreneur with the rights of a Consumer will receive information about handling the complaint by electronic correspondence to the Electronic Address provided in the complaint application.</li>
          </ul>
        </div>
        <div id="Final provisions">
          <li>
            <a href="#Final provisions">FINAL PROVISIONS</a>
          </li>
          <ul>
            <li>The Seller reserves the right to make changes to the Terms and Conditions for important reasons, i.e., changes in laws, changes in methods of payment and delivery - to the extent that these changes affect the implementation of the provisions of these Terms and Conditions.</li>
            <li>The Seller undertakes to inform the Customer of each amendment to the Terms and Conditions by posting the unified text of the Terms and Conditions on the <a target="_blank" rel="noreferrer" href="https://sklep.id-lifting.pl/content/3-regulamin-sklepu">store's Terms and Conditions link page</a>. Amendments to the Terms and Conditions do not affect orders placed by the Customer before the amendments to the Terms and Conditions became effective - such orders are processed in accordance with the provisions of the Terms and Conditions in effect on the date the order is placed.</li>
            <li>Amendments to the Terms and Conditions shall become effective 14 days after their content is available on the Link to the <a target="_blank" rel="noreferrer" href="https://sklep.id-lifting.pl/content/3-regulamin-sklepu">Store Terms and Conditions page</a>.</li>
            <li>Relevant provisions of Polish law, in particular, the provisions of the Civil Code Act of April 23, 1964 (i.e., Journal of Laws of 2018, item 1025, as amended) and the provisions of the Consumer Rights Act of May 30, 2014 (consolidated text of June 24, 2014, Journal of Laws of 2014, item 827, as amended) shall apply to matters not covered by these Terms and Conditions.</li>
            <li>If it is not possible to resolve the dispute amicably in a court of competent jurisdiction for the Seller's registered office, and in the case of a Buyer who is a Consumer or an Entrepreneur with the rights of a Consumer, the court of competent jurisdiction is according to the general rules.</li>
            <li>These Terms and Conditions come into force on 30.01.2023</li>
          </ul>
        </div>
      </ul>
      <div>
        <span>
          Attachment No. 1 - Information on exercising the right of rescission from the Sales Contract
        </span>
        <section>
          <p>
            Right of Rescission
            <br/>You have the right to withdraw from this contract within 14 days without giving any reason.
            <br/>The period for rescinding the contract expires 14 days from the date of conclusion of the Sales Contract.
          </p>
          <p>
            To exercise your right of rescission, you must inform Linqo Sp. z o.o. based in Warsaw, NIP number: 8971804573, REGON number: 360468661, e-mail: support@linqo.pl phone number: +48 71 7575444 about your decision to withdraw from this contract by an unequivocal statement (for example, a letter sent by mail or e-mail).
          </p>
          <p>
            You can use the rescission declaration form, but it is not mandatory. 
            <br/>To comply with the rescission period, it is sufficient for you to send information regarding the exercise of your right of rescission before the expiry of the rescission period.
          </p>
          <p>
            Effects of rescission from the contract
            <br/>In the event of cancellation of this contract, we will return to you all payments received from you, including the costs of service delivery, promptly and in any case no later than 14 days from the day on which we are informed of your decision to exercise your right of rescission from this contract. We will refund the payments using the same payment method you used in the original transaction unless you have expressly agreed otherwise; in any case, you will not incur any fees with this refund.
            <br/>If you have requested the commencement of the performance of services before the expiration of the rescission period, you will pay us an amount proportionate to the extent of the services performed up to the time you informed us of your rescission from this contract.
          </p>
        </section>
        <DownloadLink href="/files/Annex_2_en.pdf">Attachment No. 2</DownloadLink><br/>
        <DownloadLink href="/files/Annex_3_en.pdf">Attachment No. 3</DownloadLink>
      </div>
    </div>
  );
};
