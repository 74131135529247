import { Outlet, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from '../utils/intl';
import logo from '../assets/ruptela-logo.svg';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { NavMenu } from '../components/NavMenu';
import { MobileNavMenu } from '../components/MobileNavMenu';
import { MobileLogo } from '../components/MobileLogo';
import { SwitchLang } from '../components/SwitchLang';
import styles from './Main.module.scss';

export const Main = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Helmet>
        <html lang={i18n.language} />
        <meta charSet="utf-8" />
        <title>{t('general.title')}</title>
      </Helmet>
      <div className={styles.layout}>        
        <aside className={styles.aside}>
          <div className={styles.logo}>
            <NavLink to="/" className={styles.logoLink}>
              <img src={logo} alt="Ruptela" height={40} width={130} />
            </NavLink>
          </div>
          <NavMenu />
        </aside>
        <main className={styles.main}>
          <header className={styles.header}>
            <MobileLogo />
            <Breadcrumbs className={styles.breadcrumbs} />
            <SwitchLang />
            <MobileNavMenu />
          </header>
          <section className={styles.section}>
            <div className={styles.wrapper}>
              <Outlet />
            </div>
          </section>
          <footer className={styles.footer}>
            {t('general.copyright', { year: new Date().getFullYear() })}
          </footer>
        </main>
      </div>
    </>
  );
}
