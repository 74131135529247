import { FC, useEffect } from 'react'
import i18n from 'i18next';
// @ts-ignore
import { initReactI18next } from 'react-i18next';
import en from '../langs/en.json';
import pl from '../langs/pl.json';
import ru from '../langs/ru.json';

// @ts-ignore
export { useTranslation, Trans } from 'react-i18next';

const DefaultLang = 'en';

// @ts-ignore
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      pl: { translation: pl },
      ru: { translation: ru },
    },
    lng: DefaultLang,
    fallbackLng: DefaultLang,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'section', 'sup'],
    },
  });

interface IntlProviderProps {
  lang?: string;
}

export const IntlProvider: FC<IntlProviderProps> = ({ lang = DefaultLang, children }) => {
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang])

  return <>{children}</>;
};
