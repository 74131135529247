import { Helmet } from 'react-helmet';
import { useTranslation } from '../../utils/intl';
import { CheckServiceForm } from '../../components/CheckServiceForm';
import { Responsive } from '../../components/Responsive';

export const CheckService = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('check.title')}</title>
        <meta name="description" content={t('check.description')} />
      </Helmet>
      <h1 className="page-title">
        {t('check.form.header')}
      </h1>
      <Responsive>
        <CheckServiceForm />
      </Responsive>
    </>
  );
}
