import Spinner from '@tt2/components/build/Atoms/Spinner';
import React, { useEffect, useState } from 'react';
import styles from './OnlineRenewal.module.scss'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from '../../utils/intl';
import Icon from '@tt2/components/build/Atoms/Icon';

export const PaymentVerify = () => {
  const { sessionId } = useParams();
  const { t } = useTranslation();
  const [running, setRunning] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`/v20221206/payments/status/${sessionId}`);
        if (!response.ok) throw Error('BAD_RESPONSE');
        const { status } = await response.json();
        navigate(status === 'PAID' ? '/polish-etoll-renew-online/success' : '/polish-etoll-renew-online/error')
      } catch (error) {
        setError(Error('UNEXPECTED_ERROR'))
      }
      setRunning(false);
    })()
    // eslint-disable-next-line
  }, [sessionId])

  return (
    <div className={styles.form}>
      {running && <Spinner size={72} />}
      {error && (
        <>
          <Icon className={styles.colorError} isCentered name="error_large" size={72} />
          <p>{t('general.unexpectedError')}</p>
        </>
      )}
    </div>
  )
}
