import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import Popover from '@tt2/components/build/Atoms/Popover';
import ListItem from '@tt2/components/build/Atoms/ListItem';
import { useTranslation } from '../../utils/intl';
import styles from './SwitchLang.module.scss';

const Locales = [
  { eventKey: 'en', content: 'English' },
  { eventKey: 'pl', content: 'Polski' },
  // { eventKey: 'ru', content: 'Русский' },
];

export const SwitchLang = () => {
  const { i18n } = useTranslation()
  const location = useLocation()
  const locale = i18n.language;

  const href = useMemo(() => (
    `${location.pathname}${location.search}${location.hash}`
  ), [ location ])

  return (
    <div className={styles.root}>
      <Popover
        width={150}
        placement="bottom"
        arrowEnabled={false}
        boundByWindow
        canCloseOnBlur
        popoverContent={
          <>
            {Locales.map((item) => (
              <ListItem
                key={item.eventKey}
                className={styles.item}
                active={item.eventKey === locale}
              >
                {item.eventKey === locale ? item.content : (
                  <a href={`/${item.eventKey}${href}`} className={styles.link}>{item.content}</a>
                )}
              </ListItem>
            ))}
          </>
        }
      >
        <div className={styles.selected}>{locale}</div>
      </Popover>
    </div>
  );
};
