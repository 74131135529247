import * as Yup from 'yup';
import i18n from 'i18next';
import { RegLocatorNumber } from '../../utils/constants';

export const validationSchema = Yup.object({
  locatorNumber: Yup.string()
    .matches(
      RegLocatorNumber,
      () => i18n.t('check.form.locatorNumberFormat'),
    )
    .required(() => i18n.t('check.form.locatorNumberRequired')),
});

export type Values = Yup.InferType<typeof validationSchema>;
