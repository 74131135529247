import React, { VFC } from 'react';
import { Trans, useTranslation } from '../../utils/intl';
import { useNavigate } from 'react-router-dom';
import Icon from '@tt2/components/build/Atoms/Icon';
import styles from './OnlineRenewal.module.scss';
import Button from '@tt2/components/build/Atoms/Button';

export const ErrorMessage: VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.form}>
      <Icon className={styles.colorError} isCentered name="error_large" size={72} />
      <Trans parent="p">{'onlineRenewal.payment.error'}</Trans>
      <Button onClick={() => navigate('/polish-etoll-renew-online')}>
        {t('general.tryAgain')}
      </Button>
    </div>
  )
}
